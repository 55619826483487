import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../components/table";
import { capitalizeFirstLetter, formatDate } from "../../utils/functions";
import Icon from "../../assets/images/icons";

const WithdrawalTable = ({
  withdrawals,
  withdrawalStatus,
  handleWithdrawalStatusChange,
}: any) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "completed":
        return "#D1FFC9";
      case "pending":
        return "#CFF0FC";
      case "failed":
        return "#FFE2E2";
      default:
        return "transparent";
    }
  };
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const columns = [
    { header: "Name of Account", accessor: "fullname" },
    { header: "Amount", accessor: "amount" },
    { header: "Date", accessor: "created_at" },
    { header: "Status", accessor: "status" },
    { header: "", accessor: "id" },
  ];

  useEffect(() => {
    const filtered = withdrawals.filter((withdrwal: any) => {
      if (withdrwal.status) {
        return true;
      }
      return false;
    });
    setFilteredData(filtered);
  }, [withdrawals, withdrawalStatus]);

  const mappedData = withdrawals.map((withdrawal: any) => ({
    ...withdrawal,
    fullname: withdrawal?.user?.fullname || "N/A",
    amount: `NGN ${withdrawal?.amount}.00` || "N/A",
  }));

  const handleUserClick = (id: string) => {
    navigate(`/withdrawal/details/${id}`);
  };

  return (
    <>
      <div className="rounded-md py-3  mt-5 w-full md:block hidden">
        <div className="flex justify-between">
          <p className="text-[16px] font-semibold md:w-[50%]">
          Recent Withdrawals
            <hr className="md:bg-[#0979A1] h-[2px] md:w-[8%] md:block" />
          </p>

          <div className="md:flex gap-2 hidden">
            <span className="text-[14px] font-medium pt-2">Filter by: </span>
            <select
              className="border text-[12px] p-2 rounded bg-[#0979A1] text-white"
              value={withdrawalStatus}
              onChange={handleWithdrawalStatusChange}
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="failed">Failed</option>
            </select>
          </div>
        </div>

        {filteredData?.length > 0 ? (
          <Table
            columns={columns}
            data={mappedData}
            selectedUserId={null}
            onUserClick={handleUserClick}
          />
        ) : (
          <h1 className="text-[30px] text-center text-gray-500 opacity-80 mt-10 font-bold">
            No data available
          </h1>
        )}
      </div>

      <>
        <div className="flex justify-between mt-5">
          <p className="text-[14px] font-semibold md:hidden mt-3 ">
          Recent Withdrawals
          </p>

          <div className="md:hidden flex gap-2">
            <select
              className="border text-[10px] p-2  rounded bg-[#0979A1] text-white"
              value={withdrawalStatus}
              onChange={handleWithdrawalStatusChange}
            >
              <option value="">Filter By</option>
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="failed">Failed</option>
            </select>
          </div>
        </div>

        <hr className="bg-[#0979A1] w-[13%] h-[2px] md:hidden" />
        {filteredData.length === 0 ? (
          <h1 className="text-[18px] text-center md:hidden text-gray-500 opacity-80 mt-20 font-bold">
            No data available
          </h1>
        ) : (
          filteredData.map((item: any, index: number) => (
            <div
              key={index}
              className="flex gap-5 md:gap-10 border border-solid rounded-lg px-3 md:px-10 py-3 md:py-5 mt-5 md:hidden"
            >
              <div className="flex gap-2 px-5 justify-between flex-1">
                <div className="flex flex-col gap-2">
                  <p className="font-bold text-sm md:text-xl overflow-hidden whitespace-nowrap truncate">
                    {capitalizeFirstLetter(item.user?.fullname)}
                  </p>
                  <p className="text-[14px] md:text-lg">
                    {formatDate(item.created_at)}
                  </p>
                </div>

                <div className="flex flex-col gap-2 justify-between">
                  <Link
                    to={`/withdrawal/details/${item.id}`}
                    className="text-right text-sm md:text-xl"
                  >
                    <Icon name="dotIcon" />
                  </Link>
                  <p className="text-xs md:text-base">
                    <span
                      className={`p-2 rounded-md capitalize ${getStatusColor(
                        item.status
                      )}`}
                      style={{
                        backgroundColor: getStatusColor(item?.status),
                      }}
                    >
                      {item?.status}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))
        )}
      </>
    </>
  );
};

export default WithdrawalTable;
