import React, { useState, useEffect } from "react";
import Hero from "../components/hero";
import Footer from "../components/footer";
import Card from "../components/card";
import escrowLock from "../assets/images/escrow.png";
import approval from "../assets/images/delivery.png";
import payments from "../assets/images/payment.png";
import withdrawal from "../assets/images/withdrawals.png";
import support from "../assets/images/support.png";
import Calculator from "./pricing/calculator";
import Icon from "../assets/images/icons";
import Basket from "../assets/images/basket.png";
import mobile from "../assets/images/mobile.png";
import shop from "../assets/images/shop.png";
import money from "../assets/images/money.png";
import PromoPopUp from "../components/promo";

interface CardItems {
  logo: string;
  alt: string;
  hero: string;
  description: string;
  backgroundColor?: string;
  color?: string;
}

const cardItems: CardItems[] = [
  {
    logo: payments,
    alt: "payments",
    hero: "Why Use VendStash?",
    description:
      "Whether you're an experienced online shopper or a first-time seller, our user-friendly platform empowers you to transact with confidence.",
    backgroundColor: "transparent",
    color: "#000",
  },
  {
    logo: payments,
    alt: "payments-icon",
    hero: "Payment",
    description: "Create and share payment links in less than 3 minutes",
    backgroundColor: "#0979A1",
    color: "#fff",
  },
  {
    logo: approval,
    alt: "approval on delivery",
    hero: "Approval On Delivery",
    description: "Approve funds only on safe and completed deliveries",
    backgroundColor: "#fff",
    color: "0979A1",
  },
  {
    logo: escrowLock,
    alt: "withdrawal-icon",
    hero: "Escrow Lock",
    description: "Secure your money with escrow lock",
    backgroundColor: "#0979A1",
    color: "#fff",
  },
  {
    logo: withdrawal,
    alt: "support-icon",
    hero: "Withdrawal",
    description: "Easy and quick withdrawal for merchants",
    backgroundColor: "#fff",
    color: "0979A1",
  },
  {
    logo: support,
    alt: "support-icon",
    hero: "Support & Mediation",
    description: "Effective Customer Support and Dispute Mediation Center",
    backgroundColor: "#0979A1",
    color: "#fff",
  },
];

const cardItems2: CardItems[] = [
  {
    logo: shop,
    alt: "payments",
    hero: "Online shopping scams are real with scary stories.",
    description:
      "Billions of naira have been lost, with many shoppers falling victim",
    color: "#000",
  },
  {
    logo: mobile,
    alt: "payments-icon",
    hero: "You can avoid falling a victim by using an effective and reliable escrow solution.",
    description:
      "The merchant doesn’t get paid until the goods are safely delivered on time.",
    color: "#000",
  },
  {
    logo: money,
    alt: "approval on delivery",
    hero: "Legitimate sellers have nothing to fear.",
    description:
      "VendStash offers quick withdrawals with zero charges for Merchants.",
    color: "0979A1",
  },
];

const Landing = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(true); // Show the modal when the component mounts
  }, []);

  const handleCloseModal = () => {
    setIsModalVisible(false); // Hide the modal when it's closed
  };

  return (
    <div>
      <PromoPopUp visible={isModalVisible} handleClose={() => setIsModalVisible(false)} />

      <div className="px-[4%] pb-0 py-10 lg:py-4">
        <Hero />
      </div>

      <div className="my-4 mt- lg:mt-0 ">
        <div className="sm:max-w-screen-xl sm:mx-auto py-10 text-white">
          <div className="flex justify-center">
            <span className="md:pb-6">
              <Icon
                name="frequency"
                className="w-[50px] h-[40px] md:w-[100px] md:h-[81px]"
              />
            </span>
            <p className="text-center md:text-[30px] text-[12px] font-bold sm:text-[30px] text-[#000]">
              With VendStash, what you ordered... <br />
              <span className="md:text-[16px] text-[10px] font-semibold">
                Is what you will get! (and if not, you get a refund)
              </span>
            </p>
            <span className="md:pt-6">
              <Icon
                name="frequency1"
                className="w-[50px] h-[40px] md:w-[100px] md:h-[81px]"
              />
            </span>
          </div>
          <p className="text-center font-semibold text-[16px] text-[#000] md:mt-3 mt-10">
            Top Merchants are using VendStash Escrow services to increase
            revenue. Make your customers feel safe with VendStash
          </p>

          <section className="flex justify-center gap-14 mt-5">
            <div className="grid justify-center mt-4">
              <label className="block text-black font-normal text-[12px]">
                <input type="radio" className="w-3" />
                <s className="pl-3">Payment before Delivery only</s>
              </label>

              <label className="text-black block font-normal text-[12px]">
                <input type="radio" className="w-3" />
                <span className="pl-3">Pay into Escrow</span>
              </label>
            </div>
            <img src={Basket} alt="basket" className="w-[80px] h-[80px]" />
          </section>

          <div className="animate-fade-left flex flex-col md:flex-row justify-center mt-6 text-black sm:gap-y-12 gap-x-[4%] gap-y-10 flex-wrap">
            {cardItems.map(
              ({ logo, alt, hero, description, backgroundColor, color }) => (
                <Card
                  key={hero}
                  showCard={true}
                  classNames="flex flex-col gap-y-2 gap-x-2 pt-4 pb-8 basis-[28%]"
                  style={{ backgroundColor: backgroundColor }}
                >
                  <div
                    className="text-base md:text-lg font-semibold mt-5"
                    style={{ color: color }}
                  >
                    {hero}
                  </div>
                  <div
                    className="text-sm lg:text-[14px] font-normal"
                    style={{ color: color }}
                  >
                    {description}
                  </div>

                  <div className="ml-auto">
                    <img src={logo} alt={alt} />
                  </div>
                </Card>
              )
            )}
          </div>
        </div>
      </div>

      <section className=" w-full p-4">
        <div className="sm:max-w-screen-xl sm:mx-auto py-10 text-white">
          <p className="text-center text-xl sm:text-[30px] text-[#0979A1] font-medium">
            <span className="border-2 border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
              About
            </span>{" "}
            VendStash?
          </p>

          <div className="animate-fade-left flex flex-col md:flex-row justify-center mt-6 text-black sm:gap-y-12 gap-x-[4%] flex-wrap gap-y-10">
            {cardItems2.map(
              ({ logo, alt, hero, description, backgroundColor, color }) => (
                <Card
                  key={hero}
                  showCard={true}
                  classNames="flex flex-col gap-y-2 gap-x-2  pt-4 pb-8 basis-[28%]"
                  style={{ backgroundColor: backgroundColor }}
                >
                  <div
                    className="text-base md:text-[16px] font-medium mt-5"
                    style={{ color: color }}
                  >
                    {hero}
                  </div>

                  <div
                    className="text-sm md:text-[16px] font-medium mt-3 flex justify-between"
                    style={{ color: color }}
                  >
                    <p>{description}</p>
                    <img
                      src={logo}
                      alt={alt}
                      className="w-[100px] h-[100px] mt-6"
                    />
                  </div>
                </Card>
              )
            )}
          </div>
        </div>
      </section>
      <Calculator />

      <p className="text-[22px] text-center font-medium mt-6">
        We support Buyers and Merchants
      </p>

      <Footer />
    </div>
  );
};

export default Landing;
