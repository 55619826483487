import React, { useEffect, useRef } from "react";
import Button from "../../components/button";
import { CircleLoader } from "react-spinners";
import Toast, { showToast } from "../../components/toast";
import Card from "../../components/card";
import useRequest from "../../components/hooks/use-request";
import { useNavigate } from "react-router-dom";
import ResendCode from "./resend-code";

export default function ReleaseCode({
  code,
  setCode,
  orderId,
}: {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  orderId: string;
}) {
  const [codeError, setCodeError] = React.useState("");
  const navigate = useNavigate();
  const userToken = localStorage.getItem("token");
  const { loading, makeRequest } = useRequest(
    `/orders/complete/${orderId}`,
    "POST",
    {
      Authorization: `Bearer ${userToken}`,
    }
  );

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  function handleInputChange(value: string, index: number) {
    if (/^\d*$/.test(value)) {
      setCode((prevCode) => {
        const newCode = prevCode.split("");
        newCode[index] = value;
        if (value.length === 1 && index < inputRefs.length - 1) {
          inputRefs[index + 1].current?.focus();
        }

        return newCode.join("");
      });
    } else {
      setCodeError("Please enter digits only");
    }
  }

  function handleKeyDown(
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (event.key === "Backspace") {
      event.preventDefault();
      setCode((prevCode) => {
        const newCode = prevCode.split("");
        newCode[index] = "";
        return newCode.join("");
      });
      if (index > 0) {
        setTimeout(() => {
          inputRefs[index - 1].current?.focus();
        }, 0);
      }

      setCodeError("");
    }
  }

  function handlePaste(
    index: number,
    event: React.ClipboardEvent<HTMLInputElement>
  ) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData("text");
    if (/^\d{4}$/.test(pastedData)) {
      setCode((prevCode) => {
        const newCode = prevCode.split("");
        for (let i = 0; i < 4; i++) {
          if (index + i < inputRefs.length) {
            newCode[index + i] = pastedData.charAt(i);
          }
        }
        return newCode.join("");
      });
    } else {
      event.preventDefault();
      setCodeError("Please paste exactly 4 digits");
    }
  }

  async function verifyCode(code: string) {
    const userData = {
      code: code,
    };
    const [response, status] = await makeRequest(userData);
    if (status === 200 || status === 201) {
      showToast(response.message, true, {
        position: "top-center",
      });
      navigate(-1);
      return true;
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
    return false;
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!loading) {
      if (code.length === 4) {
        const verificationCode = code;
        const verificationResult = await verifyCode(verificationCode);
        if (verificationResult) {
          setCodeError("");
        }
      } else {
        setCodeError("Please enter a 4-digit code");
      }
    }
  }

  return (
    <Card classNames="flex flex-col p-2">
      <form
        onSubmit={handleSubmit}
        className="items-center gap-11 pb-44 pt-6 md:pt-14"
      >
        <h2 className="font-semibold text-[#040821] text-center text-base md:text-2xl">
          Payment to vendor for order #
          <span className="text-blue-500">{orderId}</span>
        </h2>

        <div className="flex flex-col gap-4 w-full text-sm md:text-base md:text-center">
          <p className="text-center py-3">
            Please enter your code to release fund
          </p>

          <p className="text-center leading-relaxed">
            Enter the OTP sent to your email address.{" "}
          </p>

          <div className="flex items-center justify-center gap-[5%] md:gap-[3%]">
            {inputRefs.map((inputRef, index) => (
              <input
                ref={inputRef}
                key={`${index}-${code[index]}`}
                value={code[index]}
                onChange={(e) => handleInputChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={(e) => handlePaste(index, e)}
                type="text"
                name="code"
                maxLength={1}
                className="h-12 w-12 text-center text-4xl border-2 focus:outline-none focus:border-blue-500 rounded-md shadow-md"
              />
            ))}
          </div>

          <p className="text-center text-red-500 text-[14px]">{codeError}</p>
        </div>

        <ResendCode orderId={orderId} />

        <div className="mt-5 flex justify-center">
          <Button size="full-half" variant="primary" type="submit">
            {loading ? (
              <CircleLoader color="#ffffff" loading={loading} size={20} />
            ) : (
              " Verify code"
            )}
          </Button>
        </div>
      </form>
      <Toast />
    </Card>
  );
}
