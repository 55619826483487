import { useEffect, useRef, useState } from "react";
import useRequest from "../../../components/hooks/use-request";
import TimelineDetails from "./timeline";
import OrderDetails from "./details";
import { I_Order, I_Timeline } from "./types";
import { useParams } from "react-router-dom";

export default function Order() {
  const { orderId } = useParams<{ orderId: string }>();
  const id = orderId!.split("_")[0];
  const [order, setOrder] = useState<I_Order | null>();
  const [timeline, setTimeline] = useState<I_Timeline>([]);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

  const { makeRequest: getOrder, loading: orderIsLoading } = useRequest(
    `/orders/${id}`,
    "GET"
  );
  const { makeRequest: getTimeline, loading: timelineIsLoading } = useRequest(
    `/orders/${id}/timeline`,
    "GET"
  );

  let refreshCount = useRef(0);

  async function fetchOrder() {
    const [orderResponse] = await getOrder();
    setOrder(orderResponse.data?.order);

    const [timelineResponse] = await getTimeline();
    setTimeline(timelineResponse.data?.timeline || []);
  }

  useEffect(() => {
    if (refreshCount.current === 0) {
      fetchOrder();
      refreshCount.current++;
    }

    const id = setInterval(() => {
      if (refreshCount.current >= 5) {
        clearTimeout(intervalId);
        return;
      }

      fetchOrder();
      refreshCount.current++;
    }, 5000);
    setIntervalId(id);

    return () => clearInterval(id);
  }, [id]);

  return (
    <>
      {order && (
        <div className="flex flex-col gap-4 sm:gap-0 lg:flex-row p-4 md:p-6 lg:p-0">
          <OrderDetails order={order} />

          <TimelineDetails order={order} timeline={timeline} />
        </div>
      )}

      {!order && (
        <div className="text-center font-extrabold mt-40 text-[30px] ">
          Loading...
        </div>
      )}

      {!order && !orderIsLoading && !timelineIsLoading && (
        <div className="text-center font-extrabold mt-40 text-[30px] ">
          Order Not Found
        </div>
      )}
    </>
  );
}
