import { useState, useCallback } from "react";
import useRequest from "../../components/hooks/use-request";
import Card from "../../components/card";
import { Controller, useForm } from "react-hook-form";
import { formatCurrency } from "../../utils/functions";
import classNames from "classnames";

const Calculator = () => {
  const { makeRequest: getTransactionFee } = useRequest(
    "/orders/transaction-fee",
    "POST"
  );
  const [transactionFees, setTransactionFees] = useState({
    total: 0,
    rate: 0,
  });
  const [amount, setAmount] = useState(0);
  const { control } = useForm();

  const handleAmountChange = useCallback(
    (event: any) => {
      const amount = parseFloat(event.target.value) || 0;
      setAmount(amount);

      getTransactionFee({ amount }).then((data) => {
        if (data && data.length > 0) {
          const [response] = data;
          const fees = response.data;
          setTransactionFees({
            total: fees?.total,
            rate: fees?.rate,
          });
        }
      });
    },
    [getTransactionFee]
  );

  return (
    <Card classNames="w-[100%] md:w-[50%] mx-auto mt-5 p-5 bg-white">
      <label className="text-[20px] text-black font-normal mt-5">
        Enter transaction amount
      </label>

      <form className="mt-5">
        <div className="flex flex-col md:flex-row gap-5">
          <Controller
            name="amount"
            control={control}
            defaultValue=""
            rules={{
              required: "Amount is required",
              minLength: {
                value: 1,
                message: "Amount must be at least 1 number ",
              },
            }}
            render={({ field }) => (
              <input
                {...field}
                type="number"
                min="0"
                placeholder="0.00"
                className="flex-grow h-12 border border-gray-500 rounded-[10px] bg-white px-4 outline-[#0979A1]"
                onChange={(e) => {
                  field.onChange(e);
                  handleAmountChange(e);
                }}
              />
            )}
          />
          <select className="h-12 border border-gray-500 rounded-[10px] bg-white px-4 outline-[#0979A1]">
            <option value="ngn">NGN</option>
          </select>
        </div>
      </form>

      <p className="text-black font-montserrat text-20 font-bold text-center mt-5">
        Transaction fee
      </p>

      <p
        className={classNames("font-bold  text-[#24242480]", "text-center ", {
          "text-red-500 text-[14px]": amount < 200,
          "text-[28px]": !(amount < 200),
        })}
      >
        {formatCurrency(
          amount > 0 ? transactionFees?.rate : 0,
          "NGN",
          "symbol"
        )}
      </p>

      <p className="text-black font-montserrat text-20 font-bold text-center mt-5">
        Total Amount
      </p>

      <p
        className={classNames("font-bold  text-[#24242480]", "text-center ", {
          "text-red-500 text-[14px]": amount < 200,
          "text-[28px]": !(amount < 200),
        })}
      >
        {formatCurrency(
          amount > 0 ? transactionFees?.total : 0,
          "NGN",
          "symbol"
        )}
      </p>
    </Card>
  );
};

export default Calculator;
