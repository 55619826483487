import React, { useState } from "react";
import Tabs from "../../components/tabs";
import Back from "../../components/back";
import { useForm } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import MerchantTab from "../../components/auth/signup/merchant";
import BuyerTab from "../../components/auth/signup/buyer";
import useRequest from "../../components/hooks/use-request";
import Toast, { showToast } from "../../components/toast";
import Button from "../../components/button";
import { CircleLoader } from "react-spinners";
import Card from "../../components/card";

const Signup = () => {
  const [activeTab, setActiveTab] = useState<"merchant" | "buyer">("merchant");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const navigate = useNavigate();
  const { loading, makeRequest } = useRequest("/user/signup", "POST");

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const { handleSubmit, control } = useForm();

  const url = new URL(window.location.href);
  const referralCode = url.searchParams.get("r");


  //account creation
  const handleCreateAccount = handleSubmit(async (formData) => {
    if (!isCheckboxChecked) {
      return;
    }
    const userData = {
      fullname: formData.fullname,
      email: formData.email,
      password: formData.password,
      referralCode: formData.referralCode || referralCode,
      userType: activeTab,
    };
   
    const [response] = await makeRequest(userData,{
      r: userData.referralCode
    });
    if (response.status) {
      localStorage.setItem("user", JSON.stringify(response?.data?.user));
      localStorage.setItem("token", response?.data?.token);
      showToast(response.message, true, {
        position: "top-center",
      });
      navigate("/signup/verify");
    } else {
      showToast(response.message, response.status, {
        position: "top-center",
      });
    }
  });

  return (
    <>
      <Back />

      <div className="flex flex-col gap-12">
        <Tabs
          activeTab={activeTab}
          tabs={["merchant", "buyer"]}
          setActiveTab={setActiveTab}
        />
      </div>

      <div className="mt-3 flex flex-col items-center gap-y-5 md:gap-y-6">
        <div className="w-full flex flex-col items-center">
          <div className="flex flex-col items-center w-full">
            {activeTab === "merchant" ? (
              <MerchantTab
                handleCreateAccount={handleCreateAccount}
                control={control}
                loading={loading}
                referralCode={referralCode}
              />
            ) : (
              <BuyerTab
                handleCreateAccount={handleCreateAccount}
                control={control}
                loading={loading}
                referralCode={referralCode}

              />
            )}
          </div>

          {!isCheckboxChecked && (
            <FormHelperText
              className={`text-center important-class-name $!important`}
              error
            >
              <span className="text-sm pt-2">
                You must agree to the terms and conditions
              </span>
            </FormHelperText>
          )}
        </div>

        <div className="flex flex-co items-center justify-center">
          <input
            type="checkbox"
            id="myCheckbox"
            className="h-8 w-8 text-green-500 border-2 border-green-500 rounded focus:outline-none focus:border-green-700 cursor-pointer"
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          />
          <label
            htmlFor="myCheckbox"
            className="ml-2 text-xs md:text-sm text-[#040821] cursor-pointer select-none"
          >
            I agree to the{" "}
            <Link to="/terms">
              <span className="text-[#0979A1]">Terms and Conditions</span>
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy">
              <span className="text-[#0979A1]">Privacy Policy</span>
            </Link>{" "}
            of VendStash.
          </label>
        </div>

        <div className="flex justify-center items-center w-full">
          <Button
            size="full-half"
            variant="primary"
            type="submit"
            onClick={handleCreateAccount}
          >
            {loading ? (
              <CircleLoader color="#ffffff" loading={loading} size={20} />
            ) : (
              "Create Account"
            )}
          </Button>
        </div>
      </div>
      <Toast />
    </>
  );
};

export default Signup;
