import React from "react";
import WalletTable from "../wallet-table";
import { WalletCards } from "./wallet-cards";

const Wallet = ({
  transaction,
  statistics,
  transactionStatus,
  handleTransactionStatusChange,
  currentPage,
  handlePageChange,
}: any) => {
  return (
    <div>
      <WalletCards statistics={statistics} />
      <WalletTable
        transaction={transaction}
        transactionStatus={transactionStatus}
        handleTransactionStatusChange={handleTransactionStatusChange}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Wallet;
