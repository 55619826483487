import { useState, useEffect } from "react";

import Orders from "../orders";
import useRequest from "../../components/hooks/use-request";
import Sidebar from "../../components/layout/sidebar";
import Wallet from "./wallet/wallet";
import Pagination from "../../components/pagination";
import Tabs from "../../components/dashboard/tabs";
import Withdraw from "./withdrawals";

interface UserData {
  fullname: string;
}

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState<
    "orders" | "wallet" | "withdrawals"
  >("orders");
  const [statistics, setStatistics] = useState([]);
  const [data, setData] = useState<UserData[]>([]);
  const [transaction, setTransaction] = useState<UserData[]>([]);
  const [withdrawals, setWithdrawals] = useState<UserData[]>([]);
  const { makeRequest: getOrders, response } = useRequest(`/orders`, "GET");
  const { makeRequest: getTransactions } = useRequest(
    "/user/wallet-transactions",
    "GET"
  );
  const { makeRequest: getWithdrawals } = useRequest("/user/withdraw", "GET");
  const [userEmail, setUserEmail] = useState<string>("");
  const userToken = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const itemsPerPage = 10;
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [transactionStatus, setTransactionStatus] = useState<string>("");
  const [withdrawalStatus, setWithdrawalStatus] = useState<string>("");

  const getProfile = useRequest("/user/profile", "GET", {
    Authorization: `Bearer ${userToken}`,
  });

  useEffect(() => {
    getProfile.makeRequest();
    const responseData = getProfile.response;
    setUserEmail(responseData?.data?.email);
    setStatistics(responseData?.data);
    if (getProfile.statusCode === 401) {
      window.location.href = "/";
    }
  }, [getProfile.statusCode]);

  useEffect(() => {
    fetchData();
  }, [
    activeTab,
    userEmail,
    selectedStatus,
    transactionStatus,
    withdrawalStatus,
    currentPage,
  ]);

  const fetchData = async () => {
    const page = currentPage;
    const limit = itemsPerPage;
    if (activeTab === "orders") {
      if (userEmail) {
        const [response] = await getOrders(undefined, {
          email: userEmail,
          status: selectedStatus,
          limit,
          page,
        });

        setData(response.data?.orders || []);
        setTotalPages(Math.ceil(response.data?.totalPages));
      }
    } else if (activeTab === "wallet") {
      const [response] = await getTransactions(undefined, {
        type: transactionStatus,
        limit,
        page,
      });
      setTransaction(response.data?.transactions || []);
      setTotalPages(Math.ceil(response.data?.totalPages));
    } else if (activeTab === "withdrawals") {
      const [response] = await getWithdrawals(undefined, {
        status: withdrawalStatus,
        limit,
        page,
      });
      setWithdrawals(response.data?.withdrawals || []);
      setTotalPages(Math.ceil(response.data?.totalPages));
    }
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const handleTransactionStatusChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTransactionStatus(event.target.value);
  };

  const handleWithdrawalStatusChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setWithdrawalStatus(event.target.value);
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    await fetchData();
  };

  return (
    <div className="md:flex block">
      <Sidebar />

      <div className="w-full mt-5 md:mt-0 px-8">
        <Tabs
          activeTab={activeTab}
          tabs={
            getProfile?.response?.data?.userType === "merchant" || "buyer"
              ? ["orders", "wallet", "withdrawals"]
              : ["orders"]
          }
          setActiveTab={setActiveTab}
        />
        {activeTab === "orders" && (
          <Orders
            data={data}
            statistics={statistics}
            selectedStatus={selectedStatus}
            handleStatusChange={handleStatusChange}
          />
        )}
        {activeTab === "wallet" && (
          <Wallet
            transaction={transaction}
            statistics={statistics}
            transactionStatus={transactionStatus}
            handleTransactionStatusChange={handleTransactionStatusChange}
          />
        )}
        {activeTab === "withdrawals" && (
          <Withdraw
            withdrawals={withdrawals}
            withdrawalStatus={withdrawalStatus}
            handleWithdrawalStatusChange={handleWithdrawalStatusChange}
          />
        )}
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
