import { ReactNode } from "react";
import { SectionTitle } from "../../../components/text";
import { I_Order, I_Timeline, I_User } from "./types";
import Toast, { showToast } from "../../../components/toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";

export default function TimelineDetails({
  timeline,
  order,
}: {
  timeline: I_Timeline;
  order: I_Order;
}) {
  const navigate = useNavigate();
  const userJSON = localStorage.getItem("user");
  const user: I_User | null = userJSON ? JSON.parse(userJSON) : null;
  const isMerchant = order.merchantEmail === user?.email;
  const [searchParams] = useSearchParams();
  const t = searchParams.get("t");
  const merchantIsViewer = atob(t || "") === order.merchantEmail || isMerchant;

  function getTimelineValue(status: string) {
    return (
      timeline.find((timeline) => timeline.status === status)?.value || false
    );
  }

  function copyLink() {
    const link = window.location.href;
    navigator.clipboard.writeText(link);
    showToast("Link copied to clipboard", true);
  }

  const handleClick = () => {
    navigate(`/upload-proof/${order.orderId}`)
  }

  return (
    <div className="w-full flex flex-col sm:p-6">
      <div className="w-full flex bg-white rounded-2xl">
        <div className="flex flex-col gap-5 w-full p-6">
          <Section>
            <Timeline status="Order Created" value={true} />

            <Action onClick={copyLink}>Copy Link</Action>
          </Section>

          <Section>
            <Timeline
              status="Payment Received"
              value={getTimelineValue("payment_received")}
            />

            {["paid", "delivered"].includes(order.status) && (
              <Action
                onClick={() => {
                  navigate(`../../release-fund/${order.orderId}`);
                }}
              >
                Release Funds To Merchant
              </Action>
            )}
          </Section>

          <Section>
            <Timeline
              status="Delivery Completed"
              value={getTimelineValue("delivery_completed")}
            />

            {order.status === "paid" && merchantIsViewer && (
              <Action onClick={handleClick}>
                Upload Proof of Delivery
              </Action>
            )}
          </Section>

          <Section>
            <Timeline
              status="Payment Disbursed"
              value={getTimelineValue("payment_disbursed")}
            />

            {getTimelineValue("payment_disbursed") && (
              <Action color="green">Payment Disbursed</Action>
            )}
          </Section>
        </div>
      </div>
      <Toast />
    </div>
  );
}

function Timeline({ status, value }: { status: string; value: boolean }) {
  return (
    <div className="flex items-center gap-4 w-[50%]">
      {value ? checked : unchecked}
      <SectionTitle size="text-[12px] md:text-[18px]">{status}</SectionTitle>
    </div>
  );
}

function Section({ children }: { children: ReactNode }) {
  return <div className="flex items-center w-full gap-2 py-3">{children}</div>;
}

function Action({
  onClick,
  children,
  color = "blue",
}: {
  onClick?: () => void;
  children: ReactNode;
  color?: "blue" | "green";
}) {
  return (
    <button
      className={classNames(
        "h-[38px] p-4 flex items-center justify-center text-[12px] font-medium rounded-md",
        {
          "bg-[#CFF0FC] hover:bg-[#4dc2ed] text-[#0978a1]": color === "blue",
          "bg-[#DEF2D9] text-[#078E42]": color === "green",
        }
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

const unchecked = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="12" transform="translate(6 6)" fill="white" />
    <mask
      id="mask0_11087_14478"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_11087_14478)">
      <path
        d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
        fill="#A0A0A0"
      />
    </g>
  </svg>
);

const checked = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_11087_14506"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_11087_14506)">
      <path
        d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
        fill="#078E42"
      />
    </g>
  </svg>
);
