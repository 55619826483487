import { ChangeEvent } from "react";
import { Subtitle } from "./text";

interface SelectProps {
  label: string;
  name: string;
  placeholder?: string;
  options: { value: string; label: string }[];
  className?: string;
  onChange: (selectedValue: string) => void;
  value?: string;
  error?: string;
}

export default function Select({
  label,
  options,
  className,
  name,
  onChange,
  value,
  error,
}: SelectProps) {
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    onChange(selectedValue);
  };

  return (
    <div className={`flex flex-col gap-2 md:gap-3 ${className}`}>
      <Subtitle>{label}</Subtitle>

      <select
        className="w-full h-[52px] border-[#4a4a4a] border-[1px] solid pl-5 p-4 bg-transparent rounded-md outline-none text-[12px] md:text-[16px]"
        value={value}
        defaultValue={value}
        onChange={handleSelectChange}
        name={name}
      >
        <option value="" disabled>
          Select {label}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {error && (
        <span className="error-text text-[#FF0101] text-[14px]">{error}</span>
      )}
    </div>
  );
}
