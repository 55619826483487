import image from "../assets/images/hero.png";

const Hero = () => {
  return (
    <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center justify-between  px-4 lg:px-0">
      {/* Content */}
      <div className="w-full lg:w-1/2 lg:pr-8 flex flex-col gap-5">
        <h1 className="text-xl sm:text-3xl text-center lg:text-left lg:text-4xl font-bold md:leading-10 lg:leading-16 tracking-wider text-black">
          Don’t Fall for Online Shopping Scams
        </h1>
        <p className="text-base text-center lg:text-left lg:text-lg font-medium text-[#040821] font-montserrat">
          Shop Smartly, Buy Safely
        </p>

        <div className="md:flex flex-col md:flex-row gap-6 mt-3 hidden">
          <a href="/create-order" className="route-link">
            <button className="bg-[#0979A1] md:w-[220px] w-full text-white rounded-md p-2 font-bold">
              Create Order
            </button>
          </a>
          <a href="/how-it-works">
            <button className="border border-[#0979A1] md:w-[220px] w-full text-[#0979A1] rounded-md p-2 font-bold">
              How It Works
            </button>
          </a>
        </div>

        <div className="md:hidden w-full">
          <a href="/create-order" className="route-link">
            <button className="bg-[#0979A1] md:w-[220px] w-full text-white rounded-md p-2 font-bold">
              Create Order
            </button>
          </a>

          <div className="flex w-full gap-6 mt-3">
            <a href="/signup" className="route-link w-full">
              <button className="bg-[#0979A1]  text-white w-full rounded-md p-2 font-bold">
                Sign up
              </button>
            </a>
            <a href="/signin" className="w-full">
              <button className="border border-[#0979A1] text-[#0979A1] w-full rounded-md p-2 font-bold">
                Log in
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Image */}
      <div className="w-full text-center lg:w-1/2 lg:mt-8 mt-7">
        <img className="w-full h-auto mx-auto" src={image} alt="svg" />
      </div>
    </div>
  );
};

export default Hero;
