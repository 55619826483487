import { ReactNode } from "react";
import { Subtitle } from "../../components/text";

export default function Pricing({
  children,
  title,
  icon,
  negative = false,
}: {
  children: string;
  title: string;
  icon?: ReactNode;
  negative?: boolean;
}) {
  return (
    <div className="flex flex-col gap-2 md:gap-3 w-full">
      <div className="flex items-center justify-between">
        <Subtitle>{title}</Subtitle>

        {icon}
      </div>

      <div className="flex items-center w-full h-[52px] p-4 bg-[#EDEFEB] rounded-[8px]">
        <Subtitle>
          {negative && "- "}₦{children}
        </Subtitle>
      </div>
    </div>
  );
}
