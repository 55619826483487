import React from "react";
import WithdrawalTable from "../withdrawal-table";

const Withdraw = ({
  withdrawals,
  withdrawalStatus,
  handleWithdrawalStatusChange,
  currentPage,
  handlePageChange,
}: any) => {
  return (
    <div>
      <WithdrawalTable
        withdrawals={withdrawals}
        withdrawalStatus={withdrawalStatus}
        handleWithdrawalStatusChange={handleWithdrawalStatusChange}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Withdraw;
