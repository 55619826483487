import React from "react";
import Card from "../../card";
import Button from "../../button";
import secure from "../../../assets/images/secure.svg";

const ResetPasswordSuccess: React.FC = () => {
  return (
    <Card classNames="flex flex-col">
      <div className="py-4 flex flex-col items-center gap-5">
        <img src={secure} alt="Reset your password" />
        <p className="text-center font-bold">Reset Link sent</p>
        <p className="text-center">
          A password reset code has been sent to your email address
        </p>

        <Button size="full-half" variant="primary" href="/reset-password">
          Continue
        </Button>
      </div>
    </Card>
  );
};

export default ResetPasswordSuccess;
