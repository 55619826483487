import React from "react";
import { capitalizeFirstLetter, formatDate } from "../../../utils/functions";

interface UserDetails {
  bankName: string;
  bankAccountNumber: string;
}

interface DetailsProps {
  wallet: {
    id: string;
    amount: number;
    created_at: string;
    user: UserDetails;
  };
}

const Details = ({ title, value }: any) => {
  return (
    <div className="flex justify-between px-4 mt-3 w-[30%] mx-auto">
      <h3 className="font-normal text-[14px]">{title}</h3>
      <h3 className="font-medium text-[14px] pt-1">{value}</h3>
    </div>
  );
};

const WalletDetails: React.FC<DetailsProps> = ({ wallet }) => {
  const createdDate = wallet?.created_at ? new Date(wallet.created_at) : null;
  const date = createdDate ? createdDate.toLocaleDateString() : "N/A";
  const time = createdDate ? createdDate.toLocaleTimeString() : "N/A";

  return (
    <div className="mt-10 pt-7 rounded-lg w-full">
      <Details title="Bank Name:" value={wallet?.user?.bankName || "N/A"} />
      <Details
        title="Account Number:"
        value={wallet?.user?.bankAccountNumber || "N/A"}
      />

      <Details title="Amount:" value={wallet?.amount || "N/A"} />

      <Details title="Date:" v value={`${formatDate(date)}`} />

      <Details title="Time:" v value={`${time}`} />

      <div className="flex justify-between px-4 mt-3 w-[30%] mx-auto">   
      </div>
    </div>
  );
};

export default WalletDetails;
