import Image from "../../assets/images/terms.png";
import Back from "../../components/back";

const Terms = () => {
  return (
    <>
      <Back />
      <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center justify-between  px-4 lg:px-0">
        <div className="w-full lg:w-1/2 lg:pr-8 flex flex-col gap-5 mt-5">
          <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
            <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
              Terms
            </span>{" "}
            and Conditions
          </h1>
          <p className="text-base text-left lg:text-lg font-normal text-[#040821] font-montserrat">
            VendStash is your trusted destination for secure and convenient
            online transactions. Our cutting-edge escrow system ensures your
            funds are safeguarded until you're delighted with your purchase.
          </p>
          <p className="text-base text-left lg:text-lg text-[#040821] font-montserrat">
            Experience seamless dispute resolution and a user-friendly interface
            that simplifies your transactions. Transparent fees, along with our
            vibrant community, establish VendStash as the top choice for
            reliable online interactions.
          </p>

          <p>VendStash is a product owned by Mitochron Hub.</p>
        </div>

        <div className="w-full text-center lg:w-1/2 lg:mt-8">
          <img className="w-fusll h-aut mx-auto" src={Image} alt="svg" />
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-8 mb-5">
        <h1 className="text-xl sm:text-[18px] font-medium text-[#0979A1]">
          <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
            Overview
          </span>
        </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, id.
          Quidem dolores reiciendis pariatur doloremque debitis sunt ut? Qui,
          atque tempora! Mollitia eveniet corporis repellendus. Rerum nobis
          asperiores qui illum necessitatibus. Sequi ipsum aliquid recusandae
          nemo, repellat et iusto totam tempora? Perspiciatis temporibus
          ratione, laboriosam itaque nostrum beatae doloremque vel possimus,
          accusamus reiciendis praesentium sed a laudantium modi iure
          consequuntur id quasi? Quibusdam debitis officiis fugit cum id iusto
          assumenda accusamus saepe eius maxime consequatur quae, quas sapiente
          nostrum voluptatibus laudantium dolorum! Molestiae adipisci alias eos
          numquam mollitia aliquid expedita id rem accusantium velit quaerat
          illo animi, aut in! Dolore est ullam non nihil architecto! Quas
          repudiandae rerum voluptates perspiciatis maiores eos. Explicabo nisi,
          repellat rerum est illo aspernatur harum sed architecto, illum
          doloribus soluta fugit laudantium accusamus ipsum! Libero perspiciatis
          autem dolorem aspernatur a harum beatae. Eligendi, blanditiis! Iusto,
          quam temporibus. Optio inventore ut repellendus provident ratione
          placeat et!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, id.
          Quidem dolores reiciendis pariatur doloremque debitis sunt ut? Qui,
          atque tempora! Mollitia eveniet corporis repellendus. Rerum nobis
          asperiores qui illum necessitatibus. Sequi ipsum aliquid recusandae
          nemo, repellat et iusto totam tempora? Perspiciatis temporibus
          ratione, laboriosam itaque nostrum beatae doloremque vel possimus,
          accusamus reiciendis praesentium sed a laudantium modi iure
          consequuntur id quasi? Quibusdam debitis officiis fugit cum id iusto
          assumenda accusamus saepe eius maxime consequatur quae, quas sapiente
          nostrum voluptatibus laudantium dolorum! Molestiae adipisci alias eos
          numquam mollitia aliquid expedita id rem accusantium velit quaerat
          illo animi, aut in! Dolore est ullam non nihil architecto! Quas
          repudiandae rerum voluptates perspiciatis maiores eos. Explicabo nisi,
          repellat rerum est illo aspernatur harum sed architecto, illum
          doloribus soluta fugit laudantium accusamus ipsum! Libero perspiciatis
          autem dolorem aspernatur a harum beatae. Eligendi, blanditiis! Iusto,
          quam temporibus. Optio inventore ut repellendus provident ratione
          placeat et!
        </p>
      </div>

      <div className="flex flex-col gap-3 mt-5 mb-5">
        <h1 className="text-xl sm:text-[18px] font-medium text-[#0979A1]">
          <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
            Personal Information
          </span>
        </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, id.
          Quidem dolores reiciendis pariatur doloremque debitis sunt ut? Qui,
          atque tempora! Mollitia eveniet corporis repellendus. Rerum nobis
          asperiores qui illum necessitatibus. Sequi ipsum aliquid recusandae
          nemo, repellat et iusto totam tempora? Perspiciatis temporibus
          ratione, laboriosam itaque nostrum beatae doloremque vel possimus,
          accusamus reiciendis praesentium sed a laudantium modi iure
          consequuntur id quasi? Quibusdam debitis officiis fugit cum id iusto
          assumenda accusamus saepe eius maxime consequatur quae, quas sapiente
          nostrum voluptatibus laudantium dolorum! Molestiae adipisci alias eos
          numquam mollitia aliquid expedita id rem accusantium velit quaerat
          illo animi, aut in! Dolore est ullam non nihil architecto! Quas
          repudiandae rerum voluptates perspiciatis maiores eos. Explicabo nisi,
          repellat rerum est illo aspernatur harum sed architecto, illum
          doloribus soluta fugit laudantium accusamus ipsum! Libero perspiciatis
          autem dolorem aspernatur a harum beatae. Eligendi, blanditiis! Iusto,
          quam temporibus. Optio inventore ut repellendus provident ratione
          placeat et!
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, id.
          Quidem dolores reiciendis pariatur doloremque debitis sunt ut? Qui,
          atque tempora! Mollitia eveniet corporis repellendus. Rerum nobis
          asperiores qui illum necessitatibus. Sequi ipsum aliquid recusandae
          nemo, repellat et iusto totam tempora? Perspiciatis temporibus
          ratione, laboriosam itaque nostrum beatae doloremque vel possimus,
          accusamus reiciendis praesentium sed a laudantium modi iure
          consequuntur id quasi? Quibusdam debitis officiis fugit cum id iusto
          assumenda accusamus saepe eius maxime consequatur quae, quas sapiente
          nostrum voluptatibus laudantium dolorum! Molestiae adipisci alias eos
          numquam mollitia aliquid expedita id rem accusantium velit quaerat
          illo animi, aut in! Dolore est ullam non nihil architecto! Quas
          repudiandae rerum voluptates perspiciatis maiores eos. Explicabo nisi,
          repellat rerum est illo aspernatur harum sed architecto, illum
          doloribus soluta fugit laudantium accusamus ipsum! Libero perspiciatis
          autem dolorem aspernatur a harum beatae. Eligendi, blanditiis! Iusto,
          quam temporibus. Optio inventore ut repellendus provident ratione
          placeat et!
        </p>
      </div>
    </>
  );
};

export default Terms;
