import React from "react";
export { ReactComponent as PadLock } from "./PadLock.svg";
export { ReactComponent as Verification } from "./verification.svg";
export { ReactComponent as Avatar } from "./default-avatar.svg";
export { ReactComponent as Avatar1 } from "./avatar.svg";

type IconName =
  | "menuIconOpen"
  | "menuIconClose"
  | "search"
  | "copy"
  | "delete"
  | "thumbsup"
  | "thumbsdown"
  | "dotIcon"
  | "edit"
  | "wallet"
  | "frequency"
  | "frequency1"
  | "x"
  | "facebook"
  | "ig"
  | "linkedin"
  | "mediation"
  | "support"
  | "whatsapp"
  | "phone"
  | "paymentIcon"
  | "paymentIconBlue"
  | "clipBoard";

interface IconProps {
  name: IconName;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ name, className }) => {
  switch (name) {
    case "menuIconOpen":
      return (
        <svg
          className={className}
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 11.6667H35"
            stroke="#040821"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M5 20H35"
            stroke="#040821"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M5 28.3333H35"
            stroke="#040821"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      );

    case "menuIconClose":
      return (
        <svg
          className={className}
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1667 25.8334L25.8333 14.1667"
            stroke="#797979"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.8333 25.8334L14.1667 14.1667"
            stroke="#797979"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "search":
      return (
        <svg
          className={className}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-5.2-5.2"
          />
          <circle cx="10" cy="10" r="8" />
        </svg>
      );

    case "copy":
      return (
        <svg
          className={className}
          width="28"
          height="26"
          viewBox="0 0 28 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="20" height="20" rx="5" fill="#F8F8F8" />
          <rect
            x="0.5"
            y="0.5"
            width="19"
            height="19"
            rx="4.5"
            stroke="black"
            stroke-opacity="0.2"
          />
          <rect x="8" y="6" width="20" height="20" rx="5" fill="#F8F8F8" />
          <rect
            x="8.5"
            y="6.5"
            width="19"
            height="19"
            rx="4.5"
            stroke="black"
            stroke-opacity="0.2"
          />
        </svg>
      );

    case "delete":
      return (
        <svg
          className={className}
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="52" height="52" rx="26" fill="#EDEDED" />
          <path
            d="M19.3308 38C18.622 38 18.0165 37.749 17.5143 37.247C17.0123 36.7448 16.7613 36.1392 16.7613 35.4304V17.3894H15.3398V15.2572H21.7362V14H30.2648V15.2572H36.6612V17.3894H35.2397V35.4304C35.2397 36.1485 34.991 36.7563 34.4935 37.2538C33.996 37.7513 33.3882 38 32.6702 38H19.3308ZM33.1076 17.3894H18.8934V35.4304C18.8934 35.5581 18.9344 35.6629 19.0164 35.7449C19.0983 35.8269 19.2032 35.8679 19.3308 35.8679H32.6702C32.7796 35.8679 32.8798 35.8223 32.9708 35.7311C33.062 35.6401 33.1076 35.5399 33.1076 35.4304V17.3894ZM22.3105 33.025H24.4423V20.2322H22.3105V33.025ZM27.5587 33.025H29.6905V20.2322H27.5587V33.025Z"
            fill="#1C1B1F"
          />
        </svg>
      );

    case "thumbsup":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.47998 18.35L10.58 20.75C10.98 21.15 11.88 21.35 12.48 21.35H16.28C17.48 21.35 18.78 20.45 19.08 19.25L21.48 11.95C21.98 10.55 21.08 9.35003 19.58 9.35003H15.58C14.98 9.35003 14.48 8.85003 14.58 8.15003L15.08 4.95003C15.28 4.05003 14.68 3.05003 13.78 2.75003C12.98 2.45003 11.98 2.85003 11.58 3.45003L7.47998 9.55003"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M2.37988 18.35V8.55002C2.37988 7.15002 2.97988 6.65002 4.37988 6.65002H5.37988C6.77988 6.65002 7.37988 7.15002 7.37988 8.55002V18.35C7.37988 19.75 6.77988 20.25 5.37988 20.25H4.37988C2.97988 20.25 2.37988 19.75 2.37988 18.35Z"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "thumbsdown":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.52 5.65002L13.42 3.25002C13.02 2.85002 12.12 2.65002 11.52 2.65002H7.71998C6.51998 2.65002 5.21998 3.55002 4.91998 4.75002L2.51998 12.05C2.01998 13.45 2.91998 14.65 4.41998 14.65H8.41998C9.01998 14.65 9.51998 15.15 9.41998 15.85L8.91998 19.05C8.71998 19.95 9.31998 20.95 10.22 21.25C11.02 21.55 12.02 21.15 12.42 20.55L16.52 14.45"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M21.6199 5.65V15.45C21.6199 16.85 21.0199 17.35 19.6199 17.35H18.6199C17.2199 17.35 16.6199 16.85 16.6199 15.45V5.65C16.6199 4.25 17.2199 3.75 18.6199 3.75H19.6199C21.0199 3.75 21.6199 4.25 21.6199 5.65Z"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "dotIcon":
      return (
        <svg
          className={className}
          width="31"
          height="25"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.09772 12.1953C4.75625 12.1953 3.65869 13.2929 3.65869 14.6343C3.65869 15.9758 4.75625 17.0734 6.09772 17.0734C7.43918 17.0734 8.53674 15.9758 8.53674 14.6343C8.53674 13.2929 7.43918 12.1953 6.09772 12.1953Z"
            fill="#B1B1B1"
            stroke="#B1B1B1"
            strokeWidth="1.5"
          />
          <path
            d="M27.5606 12.1953C26.2191 12.1953 25.1216 13.2929 25.1216 14.6343C25.1216 15.9758 26.2191 17.0734 27.5606 17.0734C28.9021 17.0734 29.9996 15.9758 29.9996 14.6343C29.9996 13.2929 28.9021 12.1953 27.5606 12.1953Z"
            fill="#B1B1B1"
            stroke="#B1B1B1"
            strokeWidth="1.5"
          />
          <path
            d="M16.8296 12.1953C15.4882 12.1953 14.3906 13.2929 14.3906 14.6343C14.3906 15.9758 15.4882 17.0734 16.8296 17.0734C18.1711 17.0734 19.2687 15.9758 19.2687 14.6343C19.2687 13.2929 18.1711 12.1953 16.8296 12.1953Z"
            fill="#B1B1B1"
            stroke="#B1B1B1"
            strokeWidth="1.5"
          />
        </svg>
      );

    case "edit":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.84006 2.4008L3.36673 8.19413C3.16006 8.41413 2.96006 8.84746 2.92006 9.14746L2.6734 11.3075C2.58673 12.0875 3.14673 12.6208 3.92006 12.4875L6.06673 12.1208C6.36673 12.0675 6.78673 11.8475 6.9934 11.6208L12.4667 5.82746C13.4134 4.82746 13.8401 3.68746 12.3667 2.29413C10.9001 0.914129 9.78673 1.4008 8.84006 2.4008Z"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 14.666H14"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "wallet":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 14.5L12.3 10.3L10.7 12.7L7.5 9.5"
            stroke="#14A256"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5 14.5H16.5V12.5"
            stroke="#14A256"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
            stroke="#14A256"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "frequency":
      return (
        <svg
          className={className}
          width="86"
          height="100"
          viewBox="0 0 86 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M84.5245 32.3049C82.0125 41.4267 27.7568 -12.3238 31.8947 4.28756C36.0327 20.899 27.6053 104.751 10.0877 98.2379C-15.1988 94.7859 21.5355 45.4706 34.7236 82.1904"
            stroke="#0979A1"
            strokeWidth="2"
            stroke-dasharray="2 2"
          />
        </svg>
      );

    case "frequency1":
      return (
        <svg
          className={className}
          width="73"
          height="103"
          viewBox="0 0 73 103"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.46953 81.138C2.0505 71.6944 66.2064 113.129 58.7385 97.7248C51.2706 82.3205 42.2615 -1.47074 60.7445 1.29763C86.2001 -0.528033 60.4008 55.2913 39.9383 22.0715"
            stroke="#0979A1"
            strokeWidth="2"
            stroke-dasharray="2 2"
          />
        </svg>
      );

    case "x":
      return (
        <svg
          className={className}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4866_15538)">
            <path
              d="M19.1668 2.49996C18.3688 3.06285 17.4853 3.49338 16.5502 3.77496C16.0483 3.19788 15.3813 2.78887 14.6394 2.60323C13.8974 2.41759 13.1164 2.46429 12.4019 2.737C11.6874 3.00972 11.0739 3.49529 10.6443 4.12805C10.2148 4.76082 9.98991 5.51024 10.0002 6.27496V7.10829C8.53569 7.14626 7.08456 6.82147 5.776 6.16283C4.46745 5.50419 3.34209 4.53215 2.50016 3.33329C2.50016 3.33329 -0.833171 10.8333 6.66683 14.1666C4.9506 15.3316 2.90613 15.9157 0.833496 15.8333C8.3335 20 17.5002 15.8333 17.5002 6.24996C17.4994 6.01783 17.4771 5.78629 17.4335 5.55829C18.284 4.71953 18.8842 3.66055 19.1668 2.49996Z"
              fill="white"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4866_15538">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case "facebook":
      return (
        <svg
          className={className}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0002 1.66663H12.5002C11.3951 1.66663 10.3353 2.10561 9.55388 2.88701C8.77248 3.66842 8.3335 4.72822 8.3335 5.83329V8.33329H5.8335V11.6666H8.3335V18.3333H11.6668V11.6666H14.1668L15.0002 8.33329H11.6668V5.83329C11.6668 5.61228 11.7546 5.40032 11.9109 5.24404C12.0672 5.08776 12.2791 4.99996 12.5002 4.99996H15.0002V1.66663Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "ig":
      return (
        <svg
          className={className}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4866_15542)">
            <path
              d="M14.1665 1.66663H5.83317C3.53198 1.66663 1.6665 3.53211 1.6665 5.83329V14.1666C1.6665 16.4678 3.53198 18.3333 5.83317 18.3333H14.1665C16.4677 18.3333 18.3332 16.4678 18.3332 14.1666V5.83329C18.3332 3.53211 16.4677 1.66663 14.1665 1.66663Z"
              fill="white"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.3333 9.47501C13.4361 10.1685 13.3176 10.8769 12.9947 11.4992C12.6718 12.1215 12.1609 12.6262 11.5346 12.9414C10.9083 13.2566 10.1986 13.3663 9.50641 13.255C8.81419 13.1436 8.17472 12.8167 7.67895 12.321C7.18318 11.8252 6.85636 11.1857 6.74497 10.4935C6.63359 9.8013 6.74331 9.09159 7.05852 8.46532C7.37374 7.83905 7.87841 7.32812 8.50074 7.00521C9.12307 6.68229 9.83138 6.56383 10.5249 6.66667C11.2324 6.77158 11.8873 7.10123 12.393 7.60693C12.8987 8.11263 13.2283 8.76757 13.3333 9.47501Z"
              fill="white"
              stroke="#0979A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5835 5.41663H14.5918"
              stroke="#0979A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4866_15542">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case "linkedin":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3335 6.66663C14.6596 6.66663 15.9313 7.19341 16.869 8.13109C17.8067 9.06877 18.3335 10.3405 18.3335 11.6666V17.5H15.0002V11.6666C15.0002 11.2246 14.8246 10.8007 14.512 10.4881C14.1994 10.1756 13.7755 9.99996 13.3335 9.99996C12.8915 9.99996 12.4675 10.1756 12.155 10.4881C11.8424 10.8007 11.6668 11.2246 11.6668 11.6666V17.5H8.3335V11.6666C8.3335 10.3405 8.86028 9.06877 9.79796 8.13109C10.7356 7.19341 12.0074 6.66663 13.3335 6.66663Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.99984 7.5H1.6665V17.5H4.99984V7.5Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.33317 4.99996C4.25365 4.99996 4.99984 4.25377 4.99984 3.33329C4.99984 2.41282 4.25365 1.66663 3.33317 1.66663C2.4127 1.66663 1.6665 2.41282 1.6665 3.33329C1.6665 4.25377 2.4127 4.99996 3.33317 4.99996Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "mediation":
      return (
        <svg
          className={className}
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.40835 15.9167H16.5825C18.3242 15.9167 19.2408 15.0001 19.2408 13.2584V2.16675H2.74084V13.2584C2.75001 15.0001 3.66668 15.9167 5.40835 15.9167Z"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.83331 2.16675H20.1666"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.33331 20.5001L11 18.6667V15.9167"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6667 20.5001L11 18.6667"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.875 10.4167L9.76249 8.00591C9.99166 7.81341 10.2942 7.86841 10.45 8.12508L11.55 9.95841C11.7058 10.2151 12.0083 10.2609 12.2375 10.0776L15.125 7.66675"
            stroke="#6A6A6A"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "paymentIcon":
      return (
        <svg
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.5" width="40" height="40" rx="20" fill="white" />
          <path
            d="M21 17.5H15"
            stroke="#0979A1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.0002 19.4692V21.5292C30.0002 22.0792 29.5602 22.5292 29.0002 22.5492H27.0402C25.9602 22.5492 24.9702 21.7592 24.8802 20.6792C24.8202 20.0492 25.0602 19.4592 25.4802 19.0492C25.8502 18.6692 26.3602 18.4492 26.9202 18.4492H29.0002C29.5602 18.4692 30.0002 18.9192 30.0002 19.4692Z"
            stroke="#0979A1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.48 19.05C25.06 19.46 24.82 20.05 24.88 20.68C24.97 21.76 25.96 22.55 27.04 22.55H29V24C29 27 27 29 24 29H15C12 29 10 27 10 24V17C10 14.28 11.64 12.38 14.19 12.06C14.45 12.02 14.72 12 15 12H24C24.26 12 24.51 12.01 24.75 12.05C27.33 12.35 29 14.26 29 17V18.45H26.92C26.36 18.45 25.85 18.67 25.48 19.05Z"
            stroke="#0979A1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "paymentIconBlue":
      return (
        <svg
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.5" width="40" height="40" rx="20" fill="#0979A1" />
          <path
            d="M21 17.5H15"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.0002 19.4692V21.5292C30.0002 22.0792 29.5602 22.5292 29.0002 22.5492H27.0402C25.9602 22.5492 24.9702 21.7592 24.8802 20.6792C24.8202 20.0492 25.0602 19.4592 25.4802 19.0492C25.8502 18.6692 26.3602 18.4492 26.9202 18.4492H29.0002C29.5602 18.4692 30.0002 18.9192 30.0002 19.4692Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.48 19.05C25.06 19.46 24.82 20.05 24.88 20.68C24.97 21.76 25.96 22.55 27.04 22.55H29V24C29 27 27 29 24 29H15C12 29 10 27 10 24V17C10 14.28 11.64 12.38 14.19 12.06C14.45 12.02 14.72 12 15 12H24C24.26 12 24.51 12.01 24.75 12.05C27.33 12.35 29 14.26 29 17V18.45H26.92C26.36 18.45 25.85 18.67 25.48 19.05Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

      case "clipBoard":
      return (
        <svg
          className={className}
          width="28"
          height="26"
          viewBox="0 0 28 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="20" height="20" rx="5" fill="#F8F8F8" />
          <rect
            x="0.5"
            y="0.5"
            width="19"
            height="19"
            rx="4.5"
            stroke="black"
            stroke-opacity="0.2"
          />
          <rect x="8" y="6" width="20" height="20" rx="5" fill="#F8F8F8" />
          <rect
            x="8.5"
            y="6.5"
            width="19"
            height="19"
            rx="4.5"
            stroke="black"
            stroke-opacity="0.2"
          />
        </svg>
      );


    default:
      return null;
  }
};

export default Icon;
