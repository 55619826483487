import { ReactNode } from "react";

export function SectionTitle({
  children,
  size = "text-[14px] md:text-[20px]",
}: {
  children: ReactNode;
  size?: string;
}) {
  return <h3 className={`${size} font-semibold`}>{children}</h3>;
}

export function Subtitle({ children }: { children: ReactNode }) {
  return (
    <h3 className="text-[#4a4a4a] text-[12px] md:text-[16px]">{children}</h3>
  );
}
