import Icon from "../../assets/images/icons";
import useRequest from "../hooks/use-request";

export default function Mediation() {
  const token = localStorage.getItem("token");
  const { makeRequest: getSessionId, loading } = useRequest(
    "/user/mediation-centre",
    "GET",
    {
      Authorization: `Bearer ${token}`,
    }
  );

  async function goToMediation() {
    const [response, status] = await getSessionId();
    if (status != 200) {
      return;
    }
    const url = `https://mc-dashboard-psi.vercel.app/auth?sessionId=${response.data.sessionId}`;
    window.open(url, "_blank");
  }

  return (
    <>
      <button
        onClick={goToMediation}
        rel="noopener noreferrer"
        className="px-12 h-full rounded-xl md:block hidden bg-[#0979A1] text-white font-bold hidde sm:blck"
      >
        {loading ? "Loading..." : "Mediation"}
      </button>

      <button
        onClick={goToMediation}
        rel="noopener noreferrer"
        className="md:hidden text-white bg-[#0979A1] px-2 h-10 text-[10px] rounded-xl font-bold hidde sm:blck"
        title="Mediation"
      >
        {loading ? "Loading..." : "Mediation"}
      </button>
    </>
  );
}
