import { Dispatch, SetStateAction, useContext, useState } from "react";
import Button from "../button";
import { Controller, UseFormSetValue } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import Input from "../input";
import { Item } from "./item";
import { I_Order } from "../../pages/create-order/types";
import BoxGift from "../../assets/images/gift.svg";
import { DeliveryOptions } from "./delivery-options";
import { SectionTitle, Subtitle } from "../text";
import DeliveryToggle from "./delivery-toggle";
import { User } from "../../utils/interfaces";
import { ItemsContext } from "../../pages/create-order/context";
import Pricing from "./pricing";

export default function OrderForm({
  handleCreateOrder,
  control,
  amountValidationMessage,
  activeTab,
  setValue,
  deliveryToggle,
  setDeliveryToggle,
}: {
  handleCreateOrder: () => void;
  control: any;
  amountValidationMessage: string;
  activeTab: "merchant" | "buyer";
  setValue: UseFormSetValue<I_Order>;
  deliveryToggle: "yes" | "no";
  setDeliveryToggle: Dispatch<SetStateAction<"yes" | "no">>;
}) {
  const { items, transactionFees, addItem } = useContext(ItemsContext);
  const userJSON = localStorage.getItem("user");
  const user: User = userJSON ? JSON.parse(userJSON) : null;

  const isMerchant = user?.userType === "merchant" || activeTab === "merchant";

  const userElements = [
    /* Buyer Details Section */
    <div key="buyer" className="flex flex-col gap-4">
      <SectionTitle>Buyer’s Details</SectionTitle>

      <div className="flex flex-col md:flex-row gap-5 flex-wrap justify-center">
        <div className="flex-[40%]">
          <Controller
            name="buyerName"
            control={control}
            rules={{
              required: "Name is required",
              minLength: {
                value: 3,
                message: "Name must be at least 3 characters",
              },
            }}
            render={({ field, fieldState }) => (
              <Input
                value={field.value}
                label="Name"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </div>

        <div className="flex-[40%]">
          <Controller
            name="buyerPhoneNumber"
            control={control}
            rules={{
              required: "Phone Number is required",
              pattern: {
                value: /^\d{11}$/,
                message: "Enter a valid eleven-digit phone number",
              },
            }}
            render={({ field, fieldState }) => (
              <Input
                value={field.value}
                label="Phone Number"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </div>

        <div className="flex-[40%]">
          <Controller
            name="buyerEmail"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Enter a valid email. E.g, example@gmail.com",
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Input
                  value={field.value}
                  label="Email Address"
                  className="lg:w-[49%]"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              </>
            )}
          />
        </div>
      </div>
    </div>,
    /* Merchant Details Section */
    <div key="merchant" className="flex flex-col gap-4">
      <SectionTitle>Merchant’s Details</SectionTitle>

      <div className="flex flex-col md:flex-row gap-5 flex-wrap justify-center">
        <div className="flex-[40%]">
          <Controller
            name="merchantBusinessName"
            control={control}
            rules={{
              required: "Business Name is required",
              minLength: {
                value: 3,
                message: "Name must be at least 3 characters",
              },
            }}
            render={({ field, fieldState }) => (
              <Input
                value={field.value}
                label="Business Name"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </div>

        <div className="flex-[40%]">
          <Controller
            name="merchantPhoneNumber"
            control={control}
            rules={{
              required: "Phone Number is required",
              pattern: {
                value: /^\d{11}$/,
                message: "Enter a valid eleven-digit phone number",
              },
            }}
            render={({ field, fieldState }) => (
              <div>
                <Input
                  value={field.value}
                  label="Business Phone Number"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
        </div>

        <div className="flex-[40%]">
          <Controller
            name="merchantEmail"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Enter a valid email. E.g, example@gmail.com",
              },
            }}
            render={({ field, fieldState }) => (
              <Input
                value={field.value}
                label="Business Email Address"
                className="lg:w-[49%]"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </div>
    </div>,
  ];
  const userDetails = isMerchant ? userElements.reverse() : userElements;

  return (
    <form onSubmit={handleCreateOrder} className="w-full my-5">
      <div className="flex flex-col justify-center gap-6 md:gap-10">
        {userDetails?.map((elem) => elem)}

        {/* Item Details Section */}
        <div className="flex flex-col gap-4">
          <SectionTitle>Item Details</SectionTitle>

          <div className="flex flex-col gap-8 justify-center">
            {items.map((_, index) => (
              <Item index={index} key={index} />
            ))}

            <div className="flex justify-center items-center">
              <Button
                size="full-half"
                type="button"
                variant="secondary"
                onClick={addItem}
              >
                Add More Items
              </Button>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1 border-t-[1px] py-4 border-[#4A4A4A]">
            <SectionTitle>Delivery Option</SectionTitle>

            <Subtitle>
              Would you like us to handle your shipping and delivery?
            </Subtitle>
          </div>

          <DeliveryToggle
            selected={deliveryToggle}
            handleSelect={(value) => {
              setDeliveryToggle(value);
            }}
          />
        </div>

        <DeliveryOptions
          show={deliveryToggle === "yes"}
          control={control}
          setValue={setValue}
        /> */}

        <div className="md:grid md:grid-cols-4 grid grid-cols-2 gap-y-4 items-center justify-between gap-6 ">
          <Pricing title="Subtotal">
            {transactionFees.amount.toString()}
          </Pricing>

          <Pricing title="Transaction Fee">
            {transactionFees.rate.toString()}
          </Pricing>

          <Pricing
            title="Promo discount"
            icon={
              <img
                src={BoxGift}
                className="hidden md:inline-block"
                alt="BoxGift"
              />
            }
            negative
          >
            {transactionFees.discount.toString()}
          </Pricing>

          <Pricing title="Total">
            {transactionFees?.total.toString() || "0"}
          </Pricing>
        </div>

        {transactionFees.amount > 0 && (
          <p className="text-red-500 text-[10px] text-right">
            {amountValidationMessage}
          </p>
        )}
      </div>
    </form>
  );
}
