import React, { useEffect, useState } from "react";
import useRequest from "../../../components/hooks/use-request";
import Input from "../../../components/input";
import Button from "../../../components/button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "../../../components/select";
import { Controller, useForm } from "react-hook-form";
import Avatar from "../../../components/avatar";
import useFileUpload from "../../../components/hooks/use-upload";
import { showToast } from "../../../components/toast";
import { CircleLoader } from "react-spinners";
import SearchSelect from "../../../components/search-select";
import { useNavigate } from "react-router-dom";

interface EditFormProps {
  response: any
  onChange: any;
  files: any;
  setFiles: any;
  user: any
}

interface BankName {
  id: string;
  name: string;
  code: string;
}

const Form = ({ onChange, files, setFiles, user }: EditFormProps) => {
  const navigate = useNavigate();
  const [isBankSelected, setIsBankSelected] = useState(false);
  const [banks, setBanks] = useState<BankName[]>([]);
  const userToken = localStorage.getItem("token");
  const { makeRequest } = useRequest("/user/edit-profile", "PUT");
  const { uploadFiles, loading } = useFileUpload("/upload");
  const { makeRequest: getBanks } = useRequest("/user/banks", "GET", {
    Authorization: `Bearer ${userToken}`,
  });

  useEffect(() => {
    const fetchBanks = async () => {
      const [response] = await getBanks();
      const sortedBanks =
        response?.data?.data?.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        ) || [];

      setBanks(sortedBanks);
    };
    fetchBanks();
  }, []);



  useEffect(() => {
    if (user) {
      setValue("profilePhoto", user.profilePhoto);
      setValue("fullname", user.fullname);
      setValue("phone", user.phone);
      setValue("socialMediaPlatform", user.socialMediaPlatform);
      setValue("socialMediaHandle", user.socialMediaHandle);
      setValue("bankAccountNumber", user.bankAccountNumber);
      setValue("bankName", user.bankName);
      setValue("bankCode", user.bankCode);
    }
  }, [user]);


  const socialMediaPlatforms: Record<string, string> = {
    instagram: "Instagram",
    twitter: "Twitter (X)",
    facebook: "Facebook",
    snapchat: "Snapchat",
    other: "Other",
  };

  const { control, setValue, handleSubmit, trigger } = useForm();

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      const response = await uploadFiles(fileList);
      if (response && response.data) {
        setFiles(response.data);
      }
      if (onChange) {
        onChange(fileList);
      }
    } else {
      setFiles([]);
    }
  }

  const profileImage = files.flat().join(",");
  const handleUpdate = handleSubmit(async (formData) => {
    const profilePhoto = files.flat().join(",");
    const newData = {
      profilePhoto: profilePhoto,
      fullname: formData.fullname,
      phone: formData.phone,
      bankName: formData.bankName,
      bankCode: formData.bankCode,
      bankAccountNumber: formData.bankAccountNumber,
      socialMediaPlatform: formData.socialMediaPlatform,
      socialMediaHandle: formData.socialMediaHandle,
      isBankSelected,
    };

    try {
      const [response] = await makeRequest(newData);
      if (response.status) {
        showToast(response.message, true, {
          position: "top-center",
        });
        navigate("/dashboard");
      } else {
        showToast(response.message[0], false, {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error("An error occurred while saving profile");
    }
  });

  const handleImageClick = () => {
    document.getElementById("imageInput")?.click();
  };


  return (
    <div className="px-1 bg-white border border-[#fff] py-6 w-full mx-auto">
      <div className="flex gap-4 w-full items-center justify-start md:p-3">
        <div className="rounded-full ring-1 ring-offset-4 ring-[#A7A7A7]"
        onClick={handleImageClick}
        >
          {profileImage || user?.profilePhoto ? (
            <Avatar size="large" src={profileImage || user?.profilePhoto} />
          ) : (
            <Avatar size="large" />
          )}
        </div>
        <label htmlFor="imageInput" className="cursor-pointer">
          <p className="text-[#0979a1] text-[12px]  lg:text-[16px]">
            Upload profile picture
          </p>
          <input
            id="imageInput" 
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </label>
      </div>
      <p className="lg:text-lg text-[12px] font-bold my-7 md:px-3">
        Your details
      </p>

      <div className="mt-5 md:px-3">
        <div className="flex flex-col flex-wrap justify-center gap-10 md:flex-row">
          <div className="flex-[40%]">
            <Controller
              name="fullname"
              control={control}
              defaultValue={user?.fullname}
              rules={{
                required: "Name is required",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  name="fullname"
                  value={field.value}
                  label="Name"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="flex-[40%]">
            <Controller
              name="phone"
              control={control}
              defaultValue={user?.phone}
              rules={{
                required: "Phone Number is required",
                pattern: {
                  value: /^\d{11}$/,
                  message: "Enter a valid eleven-digit phone number",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  label="Phone Number"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="flex-[40%]">
            <Controller
              name="socialMediaPlatform"
              control={control}
              defaultValue=""
              rules={{ required: "Select a Social Media Platform" }}
              render={({ field, fieldState }) => (
                <Select
                  label="Social Media Platform"
                  name="socialMediaPlatform"
                  options={Object.entries(socialMediaPlatforms).map(
                    (platform) => {
                      const [value, label] = platform;
                      return {
                        value,
                        label,
                      };
                    }
                  )}
                  className=""
                  onChange={(selectedValue) => {
                    field.onChange(selectedValue);
                  }}
                  value={field.value}
                  error={fieldState?.error?.message}
                />
              )}
            />
          </div>

          <div className="flex-[40%]">
            <Controller
              name="socialMediaHandle"
              control={control}
              defaultValue={user?.socialMediaHandle}
              rules={{
                required: "Media handle is required",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  label="Social Media Handle"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="flex-[40%]">
            <Controller
              name="bankAccountNumber"
              control={control}
              defaultValue=""
              rules={{
                required: "Bank Number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Enter a valid ten-digit account number",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  label="Bank Account Number"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="flex-[40%]">
            <Controller
              name="bankName"
              control={control}
              defaultValue=""
              rules={{ required: "Select a Bank" }}
              render={({ field, fieldState }) => {
                return (
                  <SearchSelect
                    label="Bank Name"
                    name="bankName"
                    options={banks.map((bank) => {
                      return {
                        label: bank.name,
                        value: bank.name,
                      };
                    })}
                    className=""
                    onChange={(selectedValue) => {
                      const selectedBank = banks.find(
                        (bank) => bank.name === selectedValue
                      );
                      if (selectedBank) {
                        setValue("bankName", selectedBank.name);
                        setValue("bankCode", selectedBank.code);
                        trigger("bankName");
                      }
                    }}
                    value={field.value}
                    error={fieldState?.error?.message}
                  />
                );
              }}
            />
          </div>

          {isBankSelected && (
              <div className="flex-[40%]">
                <Controller
                  name="bankCode"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Select a Bank" }}
                  render={({ field, fieldState }) => (
                    <Select
                      label="Bank Code"
                      name="bankCode"
                      options={banks.map((bank) => ({
                        value: bank.code,
                        label: bank.code,
                      }))}
                      className="lg:w-[50%] w-full"
                      onChange={(selectedValue) => {
                        const selectedBank = banks.find(
                          (bank) => bank.code === selectedValue
                        );
                        if (selectedBank) {
                          setValue("bankCode", selectedBank.code);
                          trigger("bankCode");
                        }
                      }}
                      value={field.value}
                      error={fieldState?.error?.message}
                    />
                  )}
                />
              </div>
          )}
        </div>

        <div className="flex justify-center items-center mt-10 w-full">
          <Button
            onClick={handleUpdate}
            size="full-half"
            variant="primary"
            type="submit"
          >
            {loading ? (
              <CircleLoader color="#ffffff" loading={loading} size={20} />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Form;
