import React, { useState, useRef, useEffect, useCallback } from "react";
import { IconButton } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import debounce from "lodash.debounce";
import Upload from "./blacklist/file-upload";

interface TextareaProps {
  label: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  name?: string;
  value?: string;
  error?: string;
}

const Textarea1: React.FC<TextareaProps> = ({
  label,
  placeholder,
  onChange,
  name,
  value,
  error,
}) => {
  const contentEditableRef = useRef<HTMLDivElement | null>(null);
  const [activeFormats, setActiveFormats] = useState<{
    [key: string]: boolean;
  }>({
    bold: false,
    italic: false,
    createLink: false,
    insertUnorderedList: false,
    justifyLeft: false,
  });

  const [showUpload, setShowUpload] = useState(false); 

  useEffect(() => {
    if (contentEditableRef.current) {
      contentEditableRef.current.innerHTML = value || "";
    }
    updateActiveFormats();
  }, [value]);

  const handleFormatClick = (command: string, value?: string) => {
    const isAlreadyActive = activeFormats[command];

    const selection = window.getSelection();
    if (selection && selection.toString().length === 0) {
      return;
    }

    let existingLink = null;

    const parentLink = selection?.anchorNode?.parentElement?.closest("a");
    if (parentLink) {
      existingLink = parentLink.href;
    }

    if (command === "createLink") {
      if (existingLink) {
        const updatedLink = prompt("Edit the URL:", existingLink);
        if (updatedLink) {
          document.execCommand("createLink", false, updatedLink);
        }
      } else {
        const newLink = prompt("Enter a URL:");
        if (newLink) {
          document.execCommand("createLink", false, newLink);
        }
      }
    } else if (command === "insertUnorderedList") {
      document.execCommand("insertUnorderedList", false, value);
      debouncedHandleDivInput();
    } else if (
      command === "justifyLeft" ||
      command === "justifyCenter" ||
      command === "justifyRight"
    ) {
      document.execCommand("styleWithCSS", false, "true");
      document.execCommand("justifyLeft", false, undefined);
      document.execCommand(command, false, undefined);
      debouncedHandleDivInput();
    } else {
      document.execCommand(command, false, value);
    }

    contentEditableRef.current?.focus();

    const updatedFormats = Object.keys(activeFormats).reduce(
      (acc, format) => ({
        ...acc,
        [format]: format === command ? !isAlreadyActive : false,
      }),
      {}
    );

    setActiveFormats(updatedFormats);
  };

  const updateActiveFormats = () => {
    const formats = ["bold", "italic", "insertUnorderedList", "justifyLeft"];
    const activeFormats: { [key: string]: boolean } = {};
    formats.forEach((format) => {
      activeFormats[format] = document.queryCommandState(format);
    });
    setActiveFormats(activeFormats);
  };

  const debouncedHandleDivInput = useCallback(
    debounce(() => {
      const contentEditable = contentEditableRef.current;
      if (contentEditable) {
        const content = contentEditable.innerHTML;
        if (onChange) {
          onChange(content);
        }
      }
    }, 2000),
    [onChange]
  );

  useEffect(() => {
    const observer = new MutationObserver(debouncedHandleDivInput);
    const contentEditable = contentEditableRef.current;
    if (contentEditable) {
      observer.observe(contentEditable, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }

    return () => {
      observer.disconnect();
      debouncedHandleDivInput.cancel();
    };
  }, [debouncedHandleDivInput]);

  return (
    <div className="text-field">
      {label && (
        <div className="lg:text-[14px] text-[12px] font-normal text-[#040821] mb-3">
          {label}
        </div>
      )}
      <div className={`textarea ${error ? "border-red-500" : ""}`}>
        <div
          ref={contentEditableRef}
          contentEditable
          suppressContentEditableWarning
          onInput={debouncedHandleDivInput}
          className={`form-control ${
            error ? "" : ""
          } text-field w-full h-[100px] border-2 solid p-5 bg-transparent rounded-md outline-none overflow-auto`}
          role="textbox"
          aria-multiline="true"
        ></div>
      </div>

      {/* {error && <div className="text-red-500 text-xs mt-1">{error}</div>} */}
      <div className="format-icons">
        <IconButton
          aria-label="Bold"
          onClick={() => handleFormatClick("bold")}
          color={activeFormats.bold ? "primary" : "default"}
        >
          <FormatBoldIcon />
        </IconButton>
        <IconButton
          aria-label="Italic"
          onClick={() => handleFormatClick("italic")}
          color={activeFormats.italic ? "primary" : "default"}
        >
          <FormatItalicIcon />
        </IconButton>
        <IconButton
          aria-label="Link"
          onClick={() => setShowUpload(!showUpload)}
          color={showUpload ? "primary" : "default"}
        >
          <AttachFileIcon />
        </IconButton>
      </div>
      <div className="flex justify-center w-[50%] mx-auto">
        {showUpload && <Upload />}
      </div>
    </div>
  );
};

export default Textarea1;
