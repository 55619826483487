import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Logo Desktop.svg";
import Icon from "../assets/images/icons";
import Support from "../assets/images/Headset.svg";
import Mail from "../assets/images/Email.svg";
import Whatsapp from "../assets/images/WhatsApp.svg";

const Footer = () => {
  return (
    <div className="mt-[70px] text-[#ffffff] bg-[#0979A1]">
      <div className="w-[94%] mx-auto pt-7">
        <section className="grid lg:grid-cols-6 grid-cols-2 pl-6 gap-7 md:gap-0 md:pl-0">
          <div className="md:block md:lg:col-span-2 md:col-span-1 hidden">
            <img src={Logo} alt="logo" />
            <p className="text-[16px] font-normal mr-3 mt-3">
              VendStash is your trusted destination for secure and convenient
              online transactions.
            </p>
          </div>

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold">Product</h3>
            <ul className="mt-4 text-[14px] font-medium leading-8">
              <li>
                <Link to="/about">About</Link>
              </li>
              <li className="relative group">
                <button className="link-style">Contact Us</button>
                <div className="absolute hidden group-hover:block w-full bg-[#0979A1] shadow-md rounded-md">
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:bg-[#2a5e71] border-b-2">
                      <Link to="/contact" className="flex gap-3">
                        <img src={Support} alt="support" />
                        Customer Support
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-[#2a5e71] border-b-2 flex gap-3">
                      <a
                        href="https://wa.me/2348065748268"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-3"
                      >
                        <img src={Whatsapp} alt="Whatsapp" />
                        +234 806 574 8268
                      </a>
                    </li>

                    <li className="px-4 py-2 hover:bg-[#2a5e71] border-b-2 flex gap-3">
                      <a
                        href="mailto:hello.vendstash@gmail.com"
                        className="flex items-center gap-3"
                      >
                        <img src={Mail} alt="mail" />
                        hello.vendstash@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms and Conditions</Link>
              </li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold">Service</h3>
            <ul className="mt-4 text-[14px] font-medium leading-8">
              <li>
                <Link to="/how-it-works"> How It Works</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="faq">FAQs</Link>
              </li>
              <li>Report A Merchant</li>
            </ul>
          </div>
          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold">Resources</h3>
            <ul className="mt-4 text-[14px] font-medium leading-8">
              <li>Blog</li>
              <li>Newsletter</li>
              <li>Events</li>
              <li>Support</li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold">Company</h3>
            <ul className="mt-4 text-[14px] font-medium leading-8">
              <li>About Mitochron Hub</li>
              <li>Careers</li>
              <li>Partnerships</li>
            </ul>
          </div>
        </section>

        <hr className="mt-12" />
        <img src={Logo} alt="logo" className="md:hidden mt-8" />
        <div className="flex justify-between mt-5 h-[50px]">
          <p className="md:text-[16px] text-[10px] font-normal">
            Copyright © {new Date().getFullYear()} VendStash
          </p>
          <section className="flex md:gap-5 gap-2">
            <Link
              to="https://x.com/vendstash?s=20"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="x" />
            </Link>

            <Link
              to="https://web.facebook.com/people/Vendstash/61554099410029/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="facebook" />
            </Link>

            <Link
              to="https://www.instagram.com/vendstash_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="ig" />
            </Link>

            <Link
              to="https://www.linkedin.com/company/vendstash/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="linkedin" />
            </Link>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Footer;
