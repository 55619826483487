import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../components/table";
import { capitalizeFirstLetter, formatDate } from "../../utils/functions";
import Icon from "../../assets/images/icons";

const WalletTable = ({
  transaction,
  transactionStatus,
  handleTransactionStatusChange,
}: any) => {

  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const columns = [
    { header: "Amount", accessor: "amount" },
    { header: "Date", accessor: "created_at" },
    { header: "Description", accessor: "type" },
    { header: "", accessor: "id" },
  ];

  useEffect(() => {
    const filtered = transaction.filter((wallet: any) => {
      if (wallet.type) {
        return true;
      }
      return false;
    });
    setFilteredData(filtered);
  }, [transaction, transactionStatus]);

  const mappedData = transaction.map((transactions: any) => ({
    ...transactions,
    email: transactions?.email || "N/A",
    amount: `NGN ${transactions?.amount}.00` || "N/A",
  }));

  const handleUserClick = (id: string) => {
    navigate(`/wallet/details/${id}`);
  };

  return (
    <>
      <div className="rounded-md py-3  mt-5 w-full md:block hidden">
        <div className="flex justify-between">
          <p className="text-[14px] font-semibold md:w-[50%]">
            Recent Transactions
            <hr className="md:bg-[#0979A1] h-[2px] md:w-[8%] md:block" />
          </p>

          <div className="md:flex gap-2 hidden">
            <span className="text-[10px] font-medium pt-2">Filter</span>
            <select
              className="border text-[12px] p-2 rounded bg-[#0979A1] text-white"
              value={transactionStatus}
              onChange={handleTransactionStatusChange}
            >
              <option value="">All</option>
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>
        </div>

        {filteredData?.length > 0 ? (
          <Table
            columns={columns}
            data={mappedData}
            selectedUserId={null}
            onUserClick={handleUserClick}
          />
        ) : (
          <h1 className="text-[30px] text-center text-gray-500 opacity-80 mt-10 font-bold">
            No data available
          </h1>
        )}
      </div>

      <>
        <div className="flex justify-between mt-5">
          <p className="text-[14px] font-semibold md:hidden mt-3 ">
            Recent Transactions
          </p>

          <div className="md:hidden flex gap-2 ">
            <select
              className="border text-[12px] p-2 mt-2 rounded bg-[#0979A1] text-white"
              value={transactionStatus}
              onChange={handleTransactionStatusChange}
            >
              <option value="">Filter By</option>
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>
        </div>

        <hr className="bg-[#0979A1] w-[13%] h-[2px] md:hidden" />
        {filteredData.length === 0 ? (
          <h1 className="text-[18px] text-center md:hidden text-gray-500 opacity-80 mt-16 font-bold">
            No data available
          </h1>
        ) : (
          filteredData.map((item: any, index: number) => (
            <div
              key={index}
              className="flex gap-5 md:gap-10 border border-solid rounded-lg px-3 md:px-10 py-3 md:py-5 mt-5 md:hidden"
            >
              <div className="flex gap-2 px-5 justify-between flex-1">
                <div className="flex flex-col gap-2">
                  
                  <p className="text-[14px] md:text-lg">
                    {formatDate(item.created_at)}
                  </p>
                </div>

                <div className="flex flex-col gap-2 justify-between">
                  <p className="font-bold text-sm md:text-xl overflow-hidden whitespace-nowrap truncate">
                    {item.amount >= 0
                      ? `+${item.amount}.00`
                      : `-${Math.abs(item.amount)}.00`}
                  </p>
                  <p className="font-normal text-[10px] md:text-xl overflow-hidden whitespace-nowrap truncate">
                    {item.type}
                  </p>
                </div>
              </div>
            </div>
          ))
        )}
      </>
    </>
  );
};

export default WalletTable;
