import { ReactNode } from "react";
import classNames from "classnames";

interface ButtonProps {
  children: ReactNode;
  variant: "primary" | "secondary";
  size: "sm" | "md" | "lg" | "full-half";
  type?: "button" | "submit" | "reset";
  href?: string;
  className?: string;
  onClick?: (e?: any) => void;
}

const Button = ({
  size = "lg",
  variant,
  children,
  type,
  href,
  onClick,
  className,
}: ButtonProps) => {
  const Component = href ? "a" : "button";
  return (
    <Component
      href={href}
      type={type}
      onClick={onClick}
      className={classNames(
        `inline-flex items-center justify-center text-center px-5 py-2 gap-2 border-2 border-[#0979A1] rounded-lg font-bold text-sm md:text-xl ${className}`,
        {
          "w-full": size === "lg",
          "w-[75%]": size === "md",
          "w-[50%]": size === "sm",
          "w-full md:w-[50%]": size === "full-half",
          "text-[#fff] bg-[#0979A1] hover:opacity-75": variant === "primary",
          "text-[#0979A1]": variant === "secondary",
        }
      )}
    >
      {children}
    </Component>
  );
};

export default Button;
