import OrdersTable from "./orders-table";

const Orders = ({
  data,
  selectedStatus,
  handleStatusChange,
  currentPage,
  handlePageChange,
}: any) => {
  return (
    <>
      <OrdersTable
        data={data}
        selectedStatus={selectedStatus}
        handleStatusChange={handleStatusChange}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default Orders;
