import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import Tabs from "../../components/tabs";
import useRequest from "../../components/hooks/use-request";
import { useNavigate } from "react-router-dom";
import { I_Item, I_Order } from "./types";
import Toast, { showToast } from "../../components/toast";
import Button from "../../components/button";
import { CircleLoader } from "react-spinners";
import OrderForm from "../../components/create-order/order-form";
import Card from "../../components/card";
import { User } from "../../utils/interfaces";
import { capitalizeFirstLetter } from "../../utils/functions";
import { ItemsContext } from "./context";

export default function CreateOrder() {
  const navigate = useNavigate();
  const [amountValidationMessage, setAmountValidationMessage] =
    useState<string>("");
  const [items, setItems] = useState<I_Item[]>([
    { name: "", amount: 0, quantity: 1, description: "" },
  ]);
  const queryParams = new URLSearchParams(window.location.search);
  const getQueryActiveTab = queryParams.get("active");
  const [activeTab, setActiveTab] = useState<"merchant" | "buyer">("merchant");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [deliveryToggle, setDeliveryToggle] = useState<"yes" | "no">("yes");
  const { makeRequest, loading } = useRequest("/orders", "POST");
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked);
  };
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { makeRequest: getTransactionFee } = useRequest(
    "/orders/transaction-fee",
    "POST"
  );

  const { makeRequest: getDiscount } = useRequest("/orders/discount", "POST");

  const [transactionFees, setTransactionFees] = useState({
    total: 0,
    rate: 0,
    discount: 0,
    amount: 0,
  });

  const userJSON = localStorage.getItem("user");
  const user: User = userJSON ? JSON.parse(userJSON) : null;
  const isLoggedIn = user != null;
  const isMerchant =
    user?.userType === "merchant" || (!isLoggedIn && activeTab === "merchant");

  const prefill = {
    buyerName: !isMerchant && isLoggedIn ? user.fullname : "",
    buyerPhone: !isMerchant && isLoggedIn && user?.phone ? user.phone : "",
    buyerEmail: !isMerchant && isLoggedIn ? user.email : "",
    merchantName:
      isMerchant && isLoggedIn ? capitalizeFirstLetter(user.fullname) : "",
    merchantPhone: isMerchant && isLoggedIn && user?.phone ? user.phone : "",
    merchantEmail: isMerchant && isLoggedIn ? user.email : "",
  };

  //Checked Box
  const { handleSubmit, control, reset, getValues, setValue } =
    useForm<I_Order>({
      defaultValues: {
        merchantBusinessName: prefill.merchantName,
        merchantPhoneNumber: prefill.merchantPhone,
        merchantEmail: prefill.merchantEmail,
        buyerName: prefill.buyerName,
        buyerPhoneNumber: prefill.buyerPhone,
        buyerEmail: prefill.buyerEmail,
        pickupAddress: null,
        deliveryAddress: null,
        // logisticsProvider: "uber",
      },
    });

  useEffect(() => {
    if (getQueryActiveTab === "buyer") setActiveTab("buyer");
  }, [getQueryActiveTab]);

  useEffect(() => {
    async function fetchTransactionFeesAndDiscount() {
      const total = getTotal(items);
      const transactionFeeData = await getTransactionFee({ amount: total });
      const [response, transactionStatus] = transactionFeeData;

      if (transactionStatus != 201) {
        setAmountValidationMessage("Amount must be between 200 and 5,000,000");

        setTransactionFees({
          total,
          rate: 0,
          amount: total,
          discount: 0,
        });
        return;
      } else {
        setAmountValidationMessage("");
      }

      const fees = response.data;
      let transactionFees = {
        total: fees?.total,
        rate: fees?.rate,
        discount: 0,
        amount: fees?.amount,
      };

      // Fetch discount if buyer email exists
      const buyerEmail = getValues("buyerEmail");
      if (buyerEmail) {
        const [discountResponse] = await getDiscount({
          buyerEmail,
          total: fees?.amount || 0,
        });
        const { discount, total } = discountResponse.data || {
          discount: 0,
          total: 0,
        };

        // Update transaction fees with the calculated discount
        transactionFees = {
          ...transactionFees,
          total,
          discount,
        };
      }

      setTransactionFees(transactionFees);
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(fetchTransactionFeesAndDiscount, 300);

    return () => clearTimeout(timeoutRef.current);
  }, [items, getValues]);

  function removeItem(index: number) {
    setItems((prevItems) => {
      if (prevItems.length > 1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      } else {
        return prevItems;
      }
    });
  }

  function updateItem(index: number, value: I_Item) {
    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  }

  function addItem() {
    setItems([...items, { name: "", amount: 0, quantity: 1, description: "" }]);
  }

  function getTotal(items: I_Item[]) {
    return items.reduce(
      (prev, current) => prev + current.amount * current.quantity,
      0
    );
  }

  /**
   * Create Order
   */
  const handleCreateOrder = handleSubmit(async (formData) => {
    if (!isCheckboxChecked) {
      return;
    }
    const createdBy = isMerchant ? "merchant" : "buyer";
    const logistics =
      deliveryToggle === "no"
        ? {}
        : {
            pickupAddress: formData.pickupAddress,
            deliveryAddress: formData.deliveryAddress,
            logisticsProvider: formData.logisticsProvider,
          };

    const orderData = {
      merchantBusinessName: formData.merchantBusinessName,
      merchantPhoneNumber: formData.merchantPhoneNumber,
      merchantEmail: formData.merchantEmail,
      buyerName: formData.buyerName,
      buyerPhoneNumber: formData.buyerPhoneNumber,
      buyerEmail: formData.buyerEmail,
      items,
      createdBy,
      ...logistics,
    };

    const [response] = await makeRequest(orderData);
    const orderId: string = response.data?.order?.orderId;
    const merchantEmail: string = response.data?.order?.merchantEmail;
    const buyerEmail: string = response.data?.order?.buyerEmail;
    localStorage.setItem("orderId", orderId);
    localStorage.setItem("merchantEmail", merchantEmail);
    localStorage.setItem("buyerEmail", buyerEmail);

    if (response.status) {
      // To identify buyers so they can pay when they get to the appropriate page
      const t = btoa(isMerchant ? merchantEmail : buyerEmail);
      showToast(response.message, true, {
        position: "top-center",
      });
      navigate(`/orders/${orderId}?t=${t}`);
      reset();
    } else {
      const cleanedMessage = response.message[0].replace(/[0.]/g, " ").trim();
      if (response.status >= 500) {
        showToast("Something went wrong", false, {
          position: "top-center",
        });
      } else {
        showToast(cleanedMessage, false, {
          position: "top-center",
        });
      }
    }
  });

  return (
    <>
      <div className="flex border-[#000] border-b-[1px] flex-col gap-12 ">
        <Tabs
          activeTab={activeTab}
          tabs={["merchant", "buyer"]}
          setActiveTab={setActiveTab}
          variant="white"
        />
      </div>

      <div className="md:p-14 p-6 bg-[#F8F8F8]">
        <Card title="Fill in your details correctly">
          <div className="w-full">
            <ItemsContext.Provider
              value={{
                items,
                removeItem,
                updateItem,
                addItem,
                transactionFees,
              }}
            >
              <OrderForm
                control={control}
                handleCreateOrder={handleCreateOrder}
                amountValidationMessage={amountValidationMessage}
                activeTab={activeTab}
                setValue={setValue}
                deliveryToggle={deliveryToggle}
                setDeliveryToggle={setDeliveryToggle}
              />
            </ItemsContext.Provider>

            {/* {isLoggedIn && <UserWallet />} */}

            {!isCheckboxChecked && (
              <p
                className={`text-center mt-3 text-[#FF0101] text-[14px] important-class-name $!important`}
              >
                You must agree to the terms and conditions
              </p>
            )}

            <div className="flex items-center justify-center mt-8">
              <input
                type="checkbox"
                id="myCheckbox"
                className="h-8 w-8 text-green-500 border-2 border-green-500 rounded focus:outline-none focus:border-green-700"
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="myCheckbox"
                className="ml-2 text-sm text-[#040821] cursor-pointer select-none"
              >
                I agree to the{" "}
                <a href="/terms" className="text-[#0979A1]">
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a href="/privacy-policy" className="text-[#0979A1]">
                  Privacy Policy
                </a>{" "}
                of VendStash.
              </label>
            </div>

            <div className="flex justify-center items-center mt-10 w-full mb-6">
              <Button
                size="full-half"
                variant="primary"
                type="submit"
                onClick={handleCreateOrder}
              >
                {loading ? (
                  <CircleLoader color="#ffffff" loading={loading} size={20} />
                ) : (
                  "Create Order"
                )}
              </Button>
            </div>

            <Toast />
          </div>
        </Card>
      </div>
    </>
  );
}
