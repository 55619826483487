import classNames from "classnames";
import { ChangeEvent } from "react";

interface TextareaProps {
  label?: string;
  placeholder?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
  value?: string;
  error?: string;
  readOnly?: boolean;
  variant?: "primary" | "secondary"; 
}

export default function Textarea({
  label,
  placeholder,
  className,
  onChange,
  name,
  value,
  error,
  readOnly = false,
  variant = "primary",
}: TextareaProps) {
  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {label && (
        <div className="lg:text-[14px] text-[12px] font-normal text-[#040821] mb-3">
          {label}
        </div>
      )}

      <textarea
        placeholder={placeholder}
        className={classNames(
          "text-field w-full h-[100px] p-5 rounded-md outline-none",
          {
            "bg-[#EDEFEB]": variant === "secondary", 
            "bg-transparent border-[#4A4A4A] border-[1px] solid": variant === "primary", 
            error: error
          }
        )}
        onChange={onChange}
        value={value}
        name={name}
        readOnly={readOnly}
      />

      {error && (
        <span className="error-text text-[#FF0101] text-[14px]">{error}</span>
      )}
    </div>
  );
}
