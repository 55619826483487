import { useState } from "react";
import Back from "../../components/back";
import Password from "../../components/auth/forgot-password/forgot-password";
import useRequest from "../../components/hooks/use-request";
import { useForm } from "react-hook-form";
import PasswordSuccess from "../../components/auth/forgot-password/reset-password-success";
import Toast, { showToast } from "../../components/toast";
import { PadLock } from "../../assets/images/icons";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const { loading, makeRequest } = useRequest("/user/forgot-password", "POST");

  const { handleSubmit, control } = useForm();

  const handleForgotPassword = handleSubmit(async (formData) => {
    const userEmail = {
      email: formData.email,
    };
    const [response] = await makeRequest(userEmail);
    if (response.status) {
      setSuccess(true);
      showToast(response.message, true, {
        position: "top-center",
      });
    }
  });

  return (
    <>
      <Back />
      {success ? (
        <PasswordSuccess />
      ) : (
        <div className="flex flex-col gap-3 lg:w-[40%] mx-auto -mt-3">
          <PadLock className="mx-auto" />
          <p className="font-medium text-xl md:text-2xl text-center font-montserrat">
            Forgot your password?
          </p>
          <p className="text-center text-[14px]">
            Enter your email below to receive your password reset instruction.
          </p>
          <Password
            handleForgotPassword={handleForgotPassword}
            loading={loading}
            control={control}
          />
        </div>
      )}
      <Toast />
    </>
  );
};

export default ForgotPassword;
