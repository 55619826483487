import { CircleLoader } from "react-spinners";
import Button from "../../button";
import Input from "../../input";
import { Controller } from "react-hook-form";
import Visible from "../../../assets/images/Eye.svg";
import Invisible from "../../../assets/images/eye-regular.svg";
import { useState } from "react";

const ResetPassword = ({
  handleResetPassword,
  control,
  loading,
}: {
  handleResetPassword: (e: any) => void;
  control: any;
  loading: boolean;
}) => {
  const [viewPassword, setViewPassword] = useState("");
  const [confirmViewPassword, setConfirmViewPassword] = useState("");

  const handleShowPassword = () => {
    setViewPassword((previousValue): any => !previousValue);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleShowConfirmPassword = () => {
    setConfirmViewPassword((previousValue): any => !previousValue);
  };

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <form
      className="mt-5 flex flex-col gap-5 items-center"
      onSubmit={handleResetPassword}
    >
      <Controller
        name="resetPasswordToken"
        control={control}
        defaultValue=""
        rules={{
          required: "Code is required",
          minLength: {
            value: 4,
            message: "Code must be 4 digits",
          },
        }}
        render={({ field, fieldState }) => (
          <Input
            value={field.value}
            placeholder="Code"
            className="w-full "
            type="number"
            label="Code"
            error={fieldState?.error?.message}
            onChange={field.onChange}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{
          required: "Password is required",
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters",
          },
          maxLength: {
            value: 70,
            message: "Password must not be more than 70 characters",
          },
          pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_\W])[A-Za-z\d@$!%*#?&_\W]{6,70}$/,
            message:
              "Password must contain at least one alphabet, one number, and one special character",
          },
        }}
        render={({ field, fieldState }) => (
          <div className="w-full relative">
            <Input
              label="Password"
              type={viewPassword ? "text" : "password"}
              value={field.value}
              placeholder="Password"
              className="w-full"
              error={fieldState?.error?.message}
              onChange={field.onChange}
            />
            <button
              onClick={handleShowPassword}
              onMouseDown={handleMouseDownPassword}
              className="w-5 h-5 absolute top-1/2 right-2 transform -translate-y-1/2 mt-2"
              type="button"
            >
              {viewPassword ? (
                <img src={Invisible} alt="password" />
              ) : (
                <img src={Visible} alt="password" />
              )}
            </button>
          </div>
        )}
      />

      <Controller
        name="confirmPassword"
        control={control}
        defaultValue=""
        rules={{
          required: "Password is required",
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters",
          },
          maxLength: {
            value: 70,
            message: "Password must not be more than 70 characters",
          },
          pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_\W])[A-Za-z\d@$!%*#?&_\W]{6,70}$/,
            message:
              "Password must contain at least one alphabet, one number, and one special character",
          },
        }}
        render={({ field, fieldState }) => (
          <div className="w-full relative">
          <Input
            label="Confirm Password"
            type={confirmViewPassword ? "text" : "password"}
            value={field.value}
            placeholder="Confirm Password"
            className="w-full"
            error={fieldState?.error?.message}
            onChange={field.onChange}
          />
          <button
            onClick={handleShowConfirmPassword}
            onMouseDown={handleMouseDownConfirmPassword}
            className="w-5 h-5 absolute top-1/2 right-2 transform -translate-y-1/2 mt-2"
            type="button"
          >
            {confirmViewPassword ? (
              <img src={Invisible} alt="password" />
            ) : (
              <img src={Visible} alt="password" />
            )}
          </button>
        </div>
        )}
      />

      <Button size="full-half" variant="primary" type="submit">
        {loading ? (
          <CircleLoader color="#ffffff" loading={loading} size={20} />
        ) : (
          " Continue"
        )}
      </Button>
    </form>
  );
};

export default ResetPassword;
