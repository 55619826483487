import Back from "../../components/back";
import ResetPassword from "../../components/auth/reset-password";
import useRequest from "../../components/hooks/use-request";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PadLock } from "../../assets/images/icons";
import Toast, { showToast } from "../../components/toast";
const Reset = () => {
  const { loading, makeRequest } = useRequest("/user/reset-password", "PUT");
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm();

  const handleResetPassword = handleSubmit(async (formData) => {
    const userEmail = {
      resetPasswordToken: formData.resetPasswordToken,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    };
    const [response] = await makeRequest(userEmail);
    if (response.status) {
      showToast(response.message, true, {
        position: "top-center",
      });
      navigate("/signin");
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }
  });

  return (
    <>
      <Back />
      <div className="flex flex-col -mt-5 lg:w-[40%] mx-auto">
        <div className="">
          <PadLock className="mx-auto" />
          <p className="font-medium text-[22px] md:text-[22px] text-center font-montserrat">
            Reset password
          </p>
          <ResetPassword
            handleResetPassword={handleResetPassword}
            loading={loading}
            control={control}
          />
        </div>
      </div>
      <Toast />
    </>
  );
};

export default Reset;
