import React from "react";
import { Modal } from "antd";
import Promo from "../../assets/images/promo.svg";
import Button from "../button";

const PromoPopUp = ({ visible, handleClose }: any) => {
  return (
    <Modal
    visible={visible}
    onCancel={handleClose}
    width={750}
    closable={true}
    footer={null}
    className="px-5 md:px-0"
    >
      <p className="text-[16px] text-center font-normal mt-20 md:mt-10">
        Special Promo Alert!
      </p>
      <h1 className="text-center text-[20px] font-bold  text-[#0979A1]">
        Unlock Secure Shopping & Get 100% Off Transaction Fee (Limited Offer)
      </h1>
      <p className="text-[15px] text-center font-medium mt-8">
        Be one of the first 100 users to sign up for Vendstash and enjoy:
      </p>
      <section className="flex justify-center">
        <img src={Promo} alt="promo" />
      </section>
      <p className="md:text-[20px] text-[17px] text-[#101942] text-center font-semibold">
        100% off transaction fees on your first order, no matter the amount.
        <br /> Shop with confidence and security.
      </p>

      <div className="flex justify-center items-center w-full mt-6">
        <Button size="full-half" variant="primary" type="submit" href="/signup">
          Sign Up
        </Button>
      </div>
      <p className="text-center text-[15px] font-semibold mt-7">
        Sign Up Now & Claim Your Free Transaction!
      </p>
    </Modal>
  );
};

export default PromoPopUp;
