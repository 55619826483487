import { Controller } from "react-hook-form";
import Button from "../../button";
import Input from "../../input";
import { CircleLoader } from "react-spinners";
const Password = ({
  handleForgotPassword,
  control,
  loading,
}: {
  handleForgotPassword: (e: any) => void;
  control: any;
  loading: boolean;
}) => {
  return (
    <form onSubmit={handleForgotPassword}>
      <Controller
        name='email'
        control={control}
        defaultValue=''
        rules={{
          required: "Email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Enter a valid email. E.g, example@gmail.com",
          },
        }}
        render={({ field, fieldState }) => (
          <Input
            value={field.value}
            placeholder='Email'
            className='w-full '
            label='Email address'
            error={fieldState?.error?.message}
            onChange={field.onChange}
          />
        )}
      />
      <div className='flex justify-center items-center mt-5'>
        <Button size='lg' variant='primary' type='submit'>
          {loading ? (
            <CircleLoader color='#ffffff' loading={loading} size={20} />
          ) : (
            " Continue"
          )}
        </Button>
      </div>
    </form>
  );
};

export default Password;
