import image from "../../assets/images/about.png";
import { Link } from "react-router-dom";
import Button from "../button";

const Hero = () => {
  return (
    <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center justify-between  px-4 lg:px-0">
      {/* Content. */}
      <div className="w-full lg:w-1/2 lg:pr-8 flex flex-col gap-5 mt-5">
        <h1 className="text-xl sm:text-[30px] font-medium text-[#0979A1]">
          <span className="border border-t-0 border-l-0 border-r-0 border-b-[#0979A1]">
            About
          </span>{" "}
          Us
        </h1>
        <p className="text-base text-center lg:text-left lg:text-lg font-normal text-[#040821] font-montserrat">
          VendStash is your trusted destination for secure and convenient online
          transactions. Our cutting-edge escrow system ensures your funds are
          safeguarded until you're delighted with your purchase.
        </p>
        <p className="text-base text-center lg:text-left lg:text-lg text-[#040821] font-montserrat">
          Experience seamless dispute resolution and a user-friendly interface
          that simplifies your transactions. Transparent fees, along with our
          vibrant community, establish VendStash as the top choice for reliable
          online interactions.
        </p>

        <p>VendStash is a product owned by Mitochron Hub.</p>
        <div className="flex flex-col md:flex-row gap-6 mt-3">
          <Link to="/signup">
            <Button size="lg" variant="primary">
              Get Started
            </Button>
          </Link>
        </div>
      </div>

      {/* Image */}
      <div className="w-full text-center lg:w-1/2 lg:mt-8">
        <img className="w-fusll h-aut mx-auto" src={image} alt="About Vendstash" />
      </div>
    </div>
  );
};

export default Hero;
