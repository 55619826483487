import React, { useEffect, useRef } from "react";
import useRequest from "../hooks/use-request";
import { useNavigate } from "react-router-dom";
import Toast, { showToast } from "../toast";
import ResendCode from "./resend-code";
import Button from "../button";
import { CircleLoader } from "react-spinners";

interface CodeInputProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  onSuccess: () => void;
}

const CodeInput: React.FC<CodeInputProps> = ({ code, setCode, onSuccess }) => {
  const [codeError, setCodeError] = React.useState("");
  const userToken = localStorage.getItem("token");
  const { loading, makeRequest } = useRequest("/user/verify-email", "PUT", {
    Authorization: `Bearer ${userToken}`,
  });
  const navigate = useNavigate();
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const isMounted = useRef(true); // Ref to track component mount state

  useEffect(() => {
    return () => {
      // Cleanup function to update isMounted ref on unmount
      isMounted.current = false;
    };
  }, []);

  function handleInputChange(value: string, index: number) {
    if (/^\d*$/.test(value)) {
      setCode((prevCode) => {
        const newCode = prevCode.split("");
        newCode[index] = value;
        if (value.length === 1 && index < inputRefs.length - 1) {
          inputRefs[index + 1].current?.focus();
        }

        return newCode.join("");
      });
    } else {
      setCodeError("Please enter digits only");
    }
  }

  function handleKeyDown(
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (event.key === "Backspace") {
      event.preventDefault();
      setCode((prevCode) => {
        const newCode = prevCode.split("");
        newCode[index] = "";
        return newCode.join("");
      });
      if (index > 0) {
        setTimeout(() => {
          inputRefs[index - 1].current?.focus();
        }, 0);
      }

      setCodeError("");
    }
  }

  function handlePaste(
    index: number,
    event: React.ClipboardEvent<HTMLInputElement>
  ) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData("text");
    if (/^\d{4}$/.test(pastedData)) {
      setCode((prevCode) => {
        const newCode = prevCode.split("");
        for (let i = 0; i < 4; i++) {
          if (index + i < inputRefs.length) {
            newCode[index + i] = pastedData.charAt(i);
          }
        }
        return newCode.join("");
      });
    } else {
      event.preventDefault();
      setCodeError("Please paste exactly 4 digits");
    }
  }

  async function verifyCode(code: string) {
    if (userToken === null) {
      navigate("/signin");
      return;
    }
    const userData = {
      verificationCode: code,
    };
    const [response, status] = await makeRequest(userData);
    if (status === 200) {
      return true;
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
    }

    return false;
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!loading) {
      if (code.length === 4) {
        const verificationCode = code;
        const verificationResult = await verifyCode(verificationCode);

        if (verificationResult) {
          onSuccess();
          setCodeError("");
        }
      } else {
        setCodeError("Please enter a 4-digit code");
      }
    }
  }

  return (
    <div className=" lg:w-[50%] w-full mx-auto ">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-left md:items-center gap-6 pt-6"
      >
        <h2 className="font-semibold text-[#040821] text-base md:text-2xl">
          Verification
        </h2>

        <div className="flex flex-col gap-4 w-full text-[16px] md:text-base md:text-center">
          <p className="">
            Enter the OTP sent to your email address.
            <span className="block text-gray-500 font-semibold mt-2">
              OTP expires after 10 minutes
            </span>
          </p>

          <div className="flex items-center justify-center gap-[3%] md:gap-[2%]">
            {inputRefs.map((inputRef, index) => (
              <input
                ref={inputRef}
                key={`${index}-${code[index]}`}
                value={code[index]}
                onChange={(e) => handleInputChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={(e) => handlePaste(index, e)}
                type="text"
                name="code"
                maxLength={1}
                className="h-12 w-12 text-center text-4xl border-2 focus:outline-none focus:border-blue-500 rounded-md shadow-md"
              />
            ))}
          </div>
          <p className="text-center text-red-500 text-[14px]">{codeError}</p>
          <ResendCode />
        </div>
        <Button size="full-half" variant="primary" type="submit">
          {loading ? (
            <CircleLoader color="#ffffff" loading={loading} size={20} />
          ) : (
            " Verify code"
          )}
        </Button>
      </form>
      <Toast />
    </div>
  );
};

export default CodeInput;
