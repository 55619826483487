import vision from "../../assets/images/vision.png";

const Vision = () => {
  return (
    <div className="bg-[#0979A11A] rounded-lg p-12 flex flex-col md:flex-row justify-between items-center">
      <img className="w-[50%]" src={vision} alt="Vendstash vision" />
      <div className="flex flex-col gap-3 text-[18px]">
        <p className="text-left text-xl sm:text-[30px] text-[#0979A1] font-medium mb-3">
          <span className="border border-t-0 border-l-0 border-r-0 border-b-[#fff]">
            Our
          </span>{" "}
          Vision
        </p>
        <p>
          At VendStash, our vision is to redefine the landscape of online
          transactions, envisioning a future where trust and innovation
          seamlessly converge. We aspire to be the cornerstone of secure digital
          commerce, providing a haven for both buyers and sellers. We aim to
          eradicate the skepticism associated with online transactions and
          become the trusted facilitator of seamless, secure, and empowering
          digital exchanges.
        </p>

        <p>
          In this vision, we see VendStash as not just a platform but a catalyst
          for transforming the way people engage in e-commerce. We envision a
          global community where individuals transact with confidence, knowing
          that VendStash is the guardian of their financial security, the
          mediator of fair resolutions, and the architect of a revolutionary
          online transaction experience.
        </p>
      </div>
    </div>
  );
};

export default Vision;
