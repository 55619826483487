import React, { useEffect, useState } from "react";
import Back from "../back";
import { Controller, useForm } from "react-hook-form";
import Input from "../input";
import { CircleLoader } from "react-spinners";
import useRequest from "../hooks/use-request";
import Textarea1 from "../text-editor";
import { showToast } from "../toast";
import Whatsapp from "../../assets/images/Whatsap.svg";
import Mail from "../../assets/images/mail.svg";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const attachment = localStorage.getItem("uploadedFiles") || "[]";
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const { makeRequest, loading } = useRequest(
    `/customer-support/create-ticket`,
    "POST"
  );
  const { handleSubmit, control, reset, formState } = useForm();
  const { errors } = formState;

  const createHelp = handleSubmit(async (formData) => {
    const help = {
      fullName: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.message,
      attachment,
    };
    const [response] = await makeRequest(help);
    if (response.status) {
      showToast('Support Request Sent', true, {
        position: "top-center",
      });
      setSuccess(response.message);
      reset();

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      showToast(response.message, false, {
        position: "top-center",
      });
      setErrorMessage(response.message);

      setTimeout(() => {
        setErrorMessage("");
      }, 7000);
    }
  });

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setErrorMessage("");
    }
  }, [errors]);

  return (
    <>
      <Back />
      <div className="w-full md:w-[50%] mx-auto items-center justify-center mt-8">
        <h1 className="font-semibold text-[30px] text-center">
          How can we help you?
        </h1>

        {!success && (
        <p className="text-[18px] font-semibold mt-7 text-center">
          Give us some of your info and tell us what you need help with
        </p>
        )}

        {success && (
          <p className="text-[18px] font-semibold mt-7 text-center text-[#0979A1]">
            Your request has been sent
          </p>
        )}
        <form className="w-full mt-14" onSubmit={createHelp}>
          <div className="md:flex justify-center gap-4 md:gap-6">
            <Controller
              name="fullName"
              control={control}
              defaultValue=""
              rules={{
                required: "Name is required",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  label="Name"
                  value={field.value}
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Enter a valid email. E.g, example@gmail.com",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  label="Email Address"
                  value={field.value}
                  className="w-full md:mt-0 mt-5"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="md:flex justify-center gap-4 md:gap-6 md:mt-8 mt-5">
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{
                required: "Phone Number is required",
                pattern: {
                  value: /^\d{11}$/,
                  message: "Enter a valid eleven-digit phone number",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value}
                  label="Phone Number (Whatsapp Number)"
                  className="w-full"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              name="subject"
              control={control}
              defaultValue=""
              rules={{
                required: "Request title is required",
                minLength: {
                  value: 3,
                  message: "Request must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  label="Request Title"
                  value={field.value}
                  className="w-full md:mt-0 mt-5"
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="mt-6">
            <Controller
              name="message"
              control={control}
              defaultValue=""
              rules={{
                required: "Description is required",
                minLength: {
                  value: 10,
                  message: "Description must be at least 10 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <Textarea1
                  label="Description"
                  value={field.value}
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <p className="text-red-500 text-center mt-4">{errorMessage}</p>
          {errorMessage && (
            <p className="text-[#0979A1] text-center mt-4">
              No account? <Link to="/signup">Sign up for free</Link>
            </p>
          )}
          <div className="flex justify-center">
            <button
              className={`rounded-md text-white px-20 text=[30px] font-bold justify-center mt-7 h-[45px] ${
                !!errorMessage || Object.keys(errors).length > 0
                  ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                  : "bg-[#0979A1] text-white"
              }`}
              disabled={!!errorMessage || Object.keys(errors).length > 0}
            >
              {loading ? (
                <CircleLoader color="#ffffff" loading={loading} size={20} />
              ) : (
                "Get Help"
              )}
            </button>
          </div>
        </form>

        {errorMessage && (
          <p className="text-center text-[14px] mt-4">
            You can chat with our Customer Support Agent on Whatsapp or send us
            an email
          </p>
        )}

        <div className="flex md:flex-row flex-col justify-center gap-4 items-center md:gap-14 h-28">
          <p className="flex">
            <a
              href="https://wa.me/2348065748268"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2"
            >
              <img src={Whatsapp} alt="Whatsapp" />
              +234 806 574 8268
            </a>
          </p>

          <p className="flex">
            <a
              href="mailto:hello.vendstash@gmail.com"
              className="flex items-center gap-2"
            >
              <img src={Mail} alt="mail" />
              hello.vendstash@gmail.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
