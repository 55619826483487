import React, { useState } from "react";
import { Controller } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../input";
import Visible from "../../../assets/images/Eye.svg";
import Invisible from "../../../assets/images/eye-regular.svg";

const MerchantTab = ({
  handleCreateAccount,
  control,
  referralCode,
}: {
  handleCreateAccount: (e: any) => void;
  control: any;
  loading: boolean;
  referralCode: string | null;
}) => {
  const [viewPassword, setViewPassword] = useState("");

  const handleShowPassword = () => {
    setViewPassword((previousValue): any => !previousValue);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className='mt-2 px-1 flex flex-col items-center gap-3 w-[80%] md:w-[35%]'>
      <h2 className='font-semibold text-md md:text-[26px] text-center'>
        Create a VendStash account
      </h2>

      <form onSubmit={handleCreateAccount} className='w-full'>
        <div className='flex flex-col justify-center gap-4 md:gap-6'>
          <div className=''>
            <Controller
              name='fullname'
              control={control}
              defaultValue=''
              rules={{
                required: "Business name is required",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  label='Business name'
                  value={field.value}
                  placeholder='e.g. Vendstash'
                  className='w-full'
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className='items-center'>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Enter a valid email. E.g, example@gmail.com",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  label='Email Address'
                  value={field.value}
                  placeholder='e.g. example@email.com'
                  className='w-full '
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className=''>
            <Controller
              name='referralCode'
              control={control}
              defaultValue=''
              
              render={({ field}) => (
                <Input
                  label='Referral Code (optional)'
                  value={referralCode ? referralCode : field.value}
                  placeholder='Enter your referral code'
                  className='w-full '
                  onChange={field.onChange}
                />
              )}
            />{" "}
          </div>


          <div className='flex relative'>
            <Controller
              name='password'
              control={control}
              defaultValue=''
              rules={{
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
                maxLength: {
                  value: 70,
                  message: "Password must not be more than 70 characters",
                },
                pattern: {
                  value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_\W])[A-Za-z\d@$!%*#?&_\W]{6,70}$/,
                  message:
                    "Password must contain at least one alphabet, one number, and one special character",
                },
              }}
              render={({ field, fieldState }) => (
                <div className='w-full relative'>
                  <Input
                    label='Password'
                    type={viewPassword ? "text" : "password"}
                    value={field.value}
                    placeholder='enter a unique password'
                    className='w-full'
                    error={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                  <button
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    className='w-5 h-5 absolute top-1/2 right-2 transform -translate-y-1/2 mt-2'
                    type='button'>
                    {viewPassword ? (
                      <img src={Invisible} alt='password'/>
                    ) : (
                      <img src={Visible} alt='password' />
                    )}
                  </button>
                </div>
              )}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MerchantTab;
